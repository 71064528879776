import React, { Component } from 'react';
import { srcSetGenerator } from './helper';

class ResponsiveBgImage extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.bgImageElement = React.createRef();
    const fixedStyles = this.props.fixedBgImage
      ? {
          backgroundAttachment: 'fixed',
        }
      : {};
    this.state = {
      style: {
        ...fixedStyles,
        width: '100%',
        height: '100%',
        WebkitbackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
    };
  }

  setBackgroundImage() {
    const imageURl = new URL(this.imageRef.current.currentSrc);
    const prettyURL = imageURl.href.split(imageURl.protocol)[1];
    this.setState({
      style: {
        ...this.state.style,
        backgroundImage: `url('${prettyURL ||
          this.imageRef.current.currentSrc}')`,
      },
    });
    this.props.updateCurrentSrc &&
      this.props.updateCurrentSrc(this.imageRef.current.currentSrc);
  }

  render() {
    const { url, children, className, sizes } = this.props;
    const { style } = this.state;

    return (
      <div className={className} ref={this.bgImageElement} style={style}>
        <img
          ref={this.imageRef}
          src={url}
          sizes={sizes}
          srcSet={srcSetGenerator(url)}
          onLoad={this.setBackgroundImage.bind(this)}
          alt="invisible"
          style={{ display: 'none', width: '100%' }}
        />
        {children}
      </div>
    );
  }
}

export default ResponsiveBgImage;
