// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

//bps:                              [1, 2, 3, 4, 5, 6, 7, 8, 9]
export const headerContentHeight = [4, 4, 4, 4, 4, 4, 5, 5, 6];

export const header = {
  logo: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  menu: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 4, 6, 8, 8, 10, 12, 14],
  },
  menulist: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 2, 3, 4],
    boxHeight: [3, 3, 3, 3, 4, 3, 3, 3, 4],
    boxWidth: [3, 3, 4, 4, 3, 2, 3, 4, 5],
  },
  contact: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 4, 2, 2, 2, 2, 2],
    left: [0, 0, 0, 2, 5, 5, 6, 7, 8],
    boxHeight: [1, 1, 1, 1, 4, 3, 3, 3, 4],
    boxWidth: [3, 3, 4, 4, 2, 2, 2, 2, 3],
  },
  address: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [0, 0, 0, 0, 2, 2, 2, 2, 2],
    left: [0, 0, 0, 0, 7, 7, 8, 9, 10],
    boxHeight: [0, 0, 0, 0, 4, 3, 3, 3, 4],
    boxWidth: [0, 0, 0, 0, 2, 2, 2, 2, 2],
  },
};
