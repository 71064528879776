import * as React from 'react';
import { GridSection, GridBox } from '../Grid';
import { Heading1, Text } from '../../helper/Typography';
import { colors } from '../../helper/Variables';
import { sectionHeight, intro } from './layouts';

interface DarkIntroSectionProps {
  texts: any;
}
export const DarkIntroSection = ({ texts }: DarkIntroSectionProps) => {
  return (
    <React.Fragment>
      <GridSection gridRows={sectionHeight.intro} className="Dark">
        <GridBox layout={intro.headline} style={{ alignContent: 'start' }}>
          <div style={{ overflow: 'auto' }}>
            <Heading1 thin={true} noMargin={true}>
              {texts.intro.title}
            </Heading1>
            <Heading1 noMargin={true}>
              {texts.intro.headline.frag1}
              <span style={{ color: colors.turquoise }}>
                {texts.intro.headline.frag2}
              </span>
              {texts.intro.headline.frag3}
            </Heading1>

            <Text.p style={{ margin: '0', padding: '80px 0 0 0' }}>
              {texts.intro.description}
            </Text.p>
          </div>
        </GridBox>
      </GridSection>
    </React.Fragment>
  );
};
