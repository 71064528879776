import * as React from 'react';
import styled, { css } from 'styled-components';
import { scrollHelper } from './../helper/scroll-helper';
import { toSlug } from '../helper/helperServices';

type AnchorProps = {
  /** The id of the anchor. This id is used by AnchorLink to define it's scroll location */
  id: string;
  history?: any;
  // use the first prop, to prevent the gatsby browser from initially scrolling
  // 80px down to the first anchor and hiding the headroom header.
  first?: boolean;
};

/**
 * The Anchor component automatically triggers an 'internal.active-anchor-change' event when scrolled near to it
 * and updates window.location hash accordingly. Anchor components can be referenced by an AnchorLinkSticky component by using the Anchor.id.
 */
export default class Anchor extends React.Component<AnchorProps> {
  anchor: HTMLAnchorElement | null = null;

  /**
   * register in global dictionary on mount
   */
  sluggedId = toSlug(this.props.id);
  componentDidMount() {
    scrollHelper.anchors[this.sluggedId] = this.anchor;
    if (this.props.history) {
      scrollHelper.setHistoryObject(this.props.history);
    }
  }

  /**
   * unregister from global dictionary on unmount
   */
  componentWillUnmount() {
    delete scrollHelper.anchors[this.sluggedId];
  }

  render() {
    return (
      <StyledAnchor
        id={this.sluggedId}
        ref={x => (this.anchor = x)}
        first={this.props.first}
      />
    );
  }
}

const StyledAnchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  ${({ first }: { first: boolean | undefined }) =>
    first &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    `}
`;
