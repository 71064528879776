// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  //bps:       [1, 2, 3, 4, 5, 6, 7, 8, 9]
  intro:       [10, 8, 8, 9, 9, 7, 7, 7, 7],
};

export const intro = {
  headline: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left:       [1, 1, 1, 1, 2, 2, 3, 4, 5],
    boxWidth:   [3, 3, 3, 6, 6, 5, 5, 5, 5],
    boxHeight:  [5, 5, 5, 5, 5, 4, 4, 4, 4],
  },
};