import React from 'react';
import styled from 'styled-components';
import { colors } from '../../helper/Variables';
import { Text } from '../../helper/Typography';
import { Link } from 'gatsby';
import { getUrlFromId } from '../../helper/helperServices';

export const JobOfferLink = ({ offer, userLanguage, darkTheme }) => (
  <Link
    style={{ height: '80px' }}
    to={`${getUrlFromId(userLanguage, 'jobs')}${offer.url}/`}
    onClick={() =>
      window.scrollTo({
        top: 0,
      })
    }
  >
    <JobOfferBlock>
      <Text.p darkTheme={darkTheme}>{offer.title}</Text.p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text.small darkTheme={darkTheme} className="showAbove-mobile2">
          {offer.location === 'Berlin' ? 'Berlin' : 'Frankfurt am Main'}
        </Text.small>
        <Icon src={'/assets/icons/arrow-icon.svg'} />
      </div>
    </JobOfferBlock>
  </Link>
);

const JobOfferBlock = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid ${colors.greyDarkest};
  height: 80px;
  p {
    max-width: 300px;
    margin: 0;
  }
  small {
    margin: 0;
  }
  div {
    justify-self: flex-end;
  }
  &:hover {
    img {
      transform: translateX(-16px);
    }
  }
`;
const Icon = styled.img`
  width: 8px;
  margin-left: 40px;
  transform: translateX(0px);
  transition: all 0.2s ease;
`;
