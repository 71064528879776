import { SimpleImage } from '../../helper/StyledHelper';
import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Notification from '../Notification/Notification';
import { toast } from 'react-toastify';
import { StaticQuery, graphql } from 'gatsby';
toast.configure();

interface SocialIconsProps {
  dark: boolean;
  isSharing?: boolean;
  locale?: string;
  pathname?: string;
}

interface SocialIconsComponentProps extends SocialIconsProps {
  url: string;
}

interface Links {
  facebook: string;
  linkedIn: string;
  twitter: string;
  instagram: string;
  xing: string;
}

export const SocialIconsComponent = ({
  isSharing = false,
  dark = true,
  locale = 'de',
  url,
  pathname,
}: SocialIconsComponentProps) => {
  const encodedURL = encodeURIComponent(pathname ? url + pathname : url);

  const socialMedia: Links = {
    facebook: isSharing
      ? `https://facebook.com/sharer/sharer.php?u=${encodedURL}`
      : 'https://www.facebook.com/neugelb/',
    linkedIn: isSharing
      ? `https://www.linkedin.com/shareArticle?mini=true&url=${encodedURL}`
      : 'https://www.linkedin.com/company/neugelb/',
    instagram: 'https://www.instagram.com/neugelb/',
    twitter: `https://twitter.com/intent/tweet/?text=&url=${encodedURL}`,
    xing: isSharing
      ? `https://www.xing.com/app/user?op=share;url=${encodedURL}`
      : 'https://www.xing.com/companies/neugelbstudiosgmbh',
  };

  return (
    <SocialIconsGroup className={'socialIcons'}>
      {Object.keys(socialMedia).map(
        (key: string) =>
          ((isSharing && key !== 'instagram') ||
            (!isSharing && key !== 'twitter')) && (
            <IconContainer key={key}>
              <a
                href={socialMedia[key]}
                target={'blank'}
                rel="noopener noreferrer"
              >
                <SocialImage
                  isActive={!dark}
                  src={`/assets/icons/social/${key}-WhiteBg.svg`}
                />
                <SocialImage
                  isActive={dark}
                  src={`/assets/icons/social/${key}.svg`}
                />
              </a>
            </IconContainer>
          )
      )}
      {isSharing && url && (
        <IconContainer>
          <CopyToClipboard
            text={pathname ? url + pathname : url}
            onCopy={() => {
              Notification.showNotification(
                'toast',
                'info',
                locale !== 'de' ? 'Copied URL' : 'URL kopiert'
              );
            }}
          >
            <div>
              <SocialImage
                isActive={!dark}
                src={`/assets/icons/social/link-WhiteBg.svg`}
              />
              <SocialImage
                isActive={dark}
                src={`/assets/icons/social/link.svg`}
              />
            </div>
          </CopyToClipboard>
        </IconContainer>
      )}
    </SocialIconsGroup>
  );
};

const SocialIconsGroup = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 0;
  @media (max-width: 767px) {
    margin-top: 28px;
    margin-right: 0;
  }
`;

const SocialImage = styled(SimpleImage)`
  opacity: ${({ isActive }: { isActive: boolean }) => (isActive ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  display: inline-block;
  cursor: pointer;
  &:not(:first-of-type) {
    margin-left: 16px;
  }
  a,
  div {
    height: 24px;
    width: 24px;
    display: inline-block;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
    }
  }
`;

const SocialIcons = ({
  locale,
  isSharing,
  dark,
  pathname,
}: SocialIconsProps) => (
  <StaticQuery
    query={socialIconsQuery}
    render={data => {
      return (
        <SocialIconsComponent
          dark={dark}
          isSharing={isSharing}
          locale={locale}
          url={data.site.siteMetadata.siteUrl}
          pathname={pathname}
        />
      );
    }}
  />
);

export const socialIconsQuery = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default SocialIcons;
