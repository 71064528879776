import { routes, translatedRoutes } from '../setup/routes';
import { pageData, PageData } from '../internationalText/caseStudies/pageData';
import { SeoDataComponentProps } from '@lws/react-components/dist/components/SeoDataComponent';

interface Meta {
  title: string;
  metaDescription?: string;
  openGraphDescription: string;
  openGraphTitle: string;
  openGraphImage: string;
}

export const getJobOfferCategory = (category: string): string => {
  switch (category) {
    case 'Design':
      return 'design';
    case 'Development':
      return 'development';
    case 'Project management':
      return 'project_management';
    case 'Office management':
      return 'office_management';
    case 'Human Resources':
      return 'human_resources';
    default:
      return 'design';
  }
};

export const getJobOfferLocation = (location: string): string => {
  switch (location) {
    case 'Frankfurt am Main':
      return 'frankfurt';
    default:
      return 'berlin';
  }
};

export const getLocalizedNodes = (nodes: any[], lang: string) =>
  nodes.filter(node => node.node_locale.substr(0, 2) === lang);

export const getLocalizedNode = (nodes: any[], lang: string) =>
  nodes.filter(node => node.node_locale.substr(0, 2) === lang)[0];

export const transformLanguageString = (lang: string | null | undefined) =>
  lang && lang.substring(0, 2);

export const getLocalizedRoutes = (lang: string | null | undefined): any[] =>
  routes.filter(route => route.locale === transformLanguageString(lang))[0]
    .routes;

export const getUrlFromId = (
  locale: string | null | undefined,
  routeID: string
): string => {
  if (locale && routeID) {
    const lang = transformLanguageString(locale);
    return `/${lang}/${
      getLocalizedRoutes(lang).find(
        (route: any): boolean => route.id === routeID
      ).url
    }/`;
  }
  return '';
};

export const CapitalizeString = (str: string): string =>
  str[0].toUpperCase() + str.slice(1, str.length);

export const toSlug = (str: string): string =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss');

export const translateRoute = (pathname: string) => {
  const pathParts = pathname.split('/').filter(e => e !== '');
  const translatedRoute = translatedRoutes[`/${pathParts[0]}/${pathParts[1]}`]
    ? translatedRoutes[`/${pathParts[0]}/${pathParts[1]}`]
    : Object.keys(translatedRoutes).filter(
        key => translatedRoutes[key] === `/${pathParts[0]}/${pathParts[1]}`
      )[0];
  return pathParts.length === 2
    ? `${translatedRoute}/`
    : `${translatedRoute}/${pathParts[2]}/`;
};

export const toLocale = (string: string): string =>
  string === 'de' ? string : 'en-US';
export const toUrlPath = (locale: string): string => locale.substr(0, 2);
export const filterArticlesAndCaseStudiesByType = (type: string) =>
  pageData.filter((page: PageData): boolean => {
    return page.type === type;
  });

export const throttle = (timeout: any, fn: Function, wait: number) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => fn(), wait);
};

export const mapMetaObject = (
  meta: Meta,
  canonical?: string,
  hostname?: string
): SeoDataComponentProps => {
  return {
    data: {
      canonical: canonical,
      metaRobotsTag: 'index, follow',
      ...meta,
      openGraphImage: {
        file: {
          url: hostname
            ? hostname + meta.openGraphImage
            : `https://neugelb.com${meta.openGraphImage}`,
        },
      },
    },
  };
};
