import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastifyOverwrite.css';

class Notification extends Component {
  static showNotification(
    type: string,
    notificationType: any,
    message: string
  ) {
    if (type === 'toast') {
      toast[notificationType](message, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }
  render() {
    return <ToastContainer style={{ zIndex: '10000' }} />;
  }
}

export default Notification;
