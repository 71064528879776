import React from 'react';
import styled from 'styled-components';
import { Heading1, Text } from '../../helper/Typography';
import { SimpleImage } from '../../helper/StyledHelper';
import SocialIcons from '../SocialIcons/SocialIcons';

export const IeFallback = () => (
  <section role="main">
    <Logo src="/assets/icons/header/logo-black.svg" />
    <Flex>
      <div style={{ marginBottom: '50px', width: '120px', height: '120px' }}>
        <SimpleImage src="/assets/icons/eye.svg" />
      </div>
      <Heading1 style={{ marginBottom: '28px' }}>Dude! Seriously...</Heading1>
      <Text.p style={{ marginBottom: '120px' }}>
        Please use a real browser to visit our website. Neugelb.com does not
        support Internet Explorer.
      </Text.p>
      <SocialIcons dark={false} />
    </Flex>
  </section>
);

const Flex = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 289px);
  width: 90%;
  margin: auto;
  @media (min-width: 768px) {
    height: calc(100vh - 241px);
    width: 60%;
  }
`;

const Logo = styled.img`
  width: 60px;
  margin: 50px;
`;
