import React, { Component } from 'react';
import anime from 'animejs';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { GridBox } from '../Grid';

class Sprite extends Component {
  spriteImagesRef = [];
  timelines = [];
  state = {
    shouldFreeze: false,
  };

  componentDidMount() {
    this.initializeAnimations();
    return this.animate();
  }

  componentDidUpdate(nextProp, nextState) {
    if (nextProp.isSticky !== this.props.isSticky) {
      this.initializeAnimations();
      this.setState({ shouldFreeze: false });
    }
    if (nextState.shouldFreeze) {
      return;
    }
    if (nextProp.animationState !== this.props.animationState) {
      return this.animate();
    }
  }

  initializeAnimations() {
    const { animationsDefinitions } = this.props;

    this.timelines = animationsDefinitions.map((animation, idx) =>
      anime({
        loop: animation.loop,
        delay: animation.delay || 0,
        targets: this.spriteImagesRef[idx],
        direction: animation.reverse ? 'reverse' : 'normal',
        duration: animation.duration,
        easing: `steps(${animation.amountOfFrames - 1})`,
        translateX: `-${100 - 100 / animation.amountOfFrames}%`,
        autoplay: true,
      })
    );
  }

  animate() {
    const {
      animationsDefinitions,
      name,
      isActive,
      animationState,
      setAnimationState,
    } = this.props;

    if (animationState === 'freezed') {
      return this.setState({ shouldFreeze: true });
    } else if (isActive && typeof animationState !== 'undefined') {
      let { chainToState, freezeAfter } = animationsDefinitions[animationState];

      this.timelines.forEach(timeline => timeline && timeline.pause());

      requestAnimationFrame(() => this.timelines[animationState].play());

      freezeAfter &&
        requestAnimationFrame(() => this.setState({ shouldFreeze: true }));

      return this.timelines[animationState].finished.then(() => {
        return (
          chainToState &&
          setAnimationState(
            name,
            animationsDefinitions[animationState].chainToState
          )
        );
      });
    }
  }

  setAnimationForStickyState(status) {
    const {
      setAnimationState,
      name,
      onRelease,
      onScroll,
      originalAnimState,
    } = this.props;

    switch (status) {
      case 1:
        //position released
        return onRelease && onRelease();
      case 2:
        //on scroll
        return onScroll && onScroll();
      default:
        //original position
        return setAnimationState(name, originalAnimState);
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      id,
      layout,
      translateTop,
      name,
      startId,
      endId,
      animationState,
      isSticky,
      animationsDefinitions,
      hideWhenFreeze,
    } = this.props;

    if (hideWhenFreeze && this.state.shouldFreeze) {
      return null;
    }

    if (isSticky) {
      return (
        <SpriteStickyContainer
          id={id}
          translateTop={translateTop}
          layout={layout}
        >
          <Sticky
            enabled={isSticky}
            top={`#${startId}`}
            bottomBoundary={`#${endId}`}
            onStateChange={({ status }) =>
              isSticky && this.setAnimationForStickyState(status)
            }
            shouldFreeze={() => this.state.shouldFreeze}
          >
            {animationsDefinitions.map((state, idx) => (
              <SpriteImage
                key={`${name}-${idx}`}
                ref={node => (this.spriteImagesRef[idx] = node)}
                src={`/animations/${name}/${name}-v${this.props.version || 1}-${
                  state.id
                }.svg`}
                visible={animationState === idx}
              />
            ))}
          </Sticky>
        </SpriteStickyContainer>
      );
    } else {
      return (
        <SpriteContainer id={id} translateTop={translateTop} layout={layout}>
          <div
            style={{ overflow: 'hidden', position: 'relative', height: '100%' }}
          >
            {animationsDefinitions.map((state, idx) => (
              <SpriteImage
                key={`${name}-${idx}`}
                ref={node => (this.spriteImagesRef[idx] = node)}
                src={`/animations/${name}/${name}-v${this.props.version || 1}-${
                  state.id
                }.svg`}
                visible={animationState === idx}
              />
            ))}
          </div>
        </SpriteContainer>
      );
    }
  }
}

export default Sprite;

const SpriteStickyContainer = styled(GridBox)`
  position: relative;
  /* overflow-x: hidden; */
  & > div > div {
    overflow: hidden;
    @media (max-width: 320px) {
      width: 100px !important;
      height: 100px !important;
    }
    @media (min-width: 321px) {
      width: 112px !important;
      height: 112px !important;
    }

    @media (min-width: 768px) {
      width: 120px !important;
      height: 120px !important;
    }
    @media (min-width: 1280px) {
      width: 160px !important;
      height: 160px !important;
    }
  }
`;

const SpriteContainer = styled(GridBox)`
  position: relative;
  overflow: hidden;
  @media (max-width: 320px) {
    width: 100px !important;
    height: 100px !important;
  }
  @media (min-width: 321px) {
    width: 112px !important;
    height: 112px !important;
  }

  @media (min-width: 768px) {
    width: 120px !important;
    height: 120px !important;
  }
  @media (min-width: 1280px) {
    width: 160px !important;
    height: 160px !important;
  }
`;

export const SpriteImage = styled.img`
  height: 100% !important;
  width: auto !important;
  position: absolute;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;
