import * as React from 'react';
import { GridBox } from '../Grid';
import { list, navigationHeadline } from './layouts';
import { Heading1, Text, Heading2 } from '../../helper/Typography';
import styled from 'styled-components';
import { scrollHelper } from '../../helper/scroll-helper';

interface ScrollNavigationRendererProps {
  navigationItems: string[];
  texts: any;
}
export class ScrollNavigationRenderer extends React.Component<
  ScrollNavigationRendererProps
> {
  render() {
    const { navigationItems, texts } = this.props;
    return (
      <React.Fragment>
        <GridBox layout={navigationHeadline}>
          <Heading2>{texts.intro.navigationHeadline}</Heading2>
        </GridBox>
        {navigationItems.map((stage: string, index: number) => {
          const isEven = index % 2 == 0;
          return (
            <GridBox
              key={stage}
              layout={list[`box${index + 1}`]}
              style={{ alignContent: 'stretch' }}
            >
              <ScrollTrigger
                blackBg={isEven ? true : false}
                onClick={e => scrollHelper.scrollSmoothlyTo(e, stage, 80)}
              >
                <Heading1 darkTheme={isEven ? true : false}>
                  {index + 1}
                </Heading1>
                <Text.p darkTheme={isEven ? true : false}>
                  <strong>{texts[stage].title}</strong>
                </Text.p>
              </ScrollTrigger>
            </GridBox>
          );
        })}
      </React.Fragment>
    );
  }
}

const ScrollTrigger = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ blackBg }: { blackBg: boolean }) =>
    blackBg === true ? '#000' : 'transparent'};
  h1,
  p {
    margin-bottom: 0;
  }
  h1 {
    line-height: 64px;
  }
`;
