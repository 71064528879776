// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const navigationHeadline = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top:        [6, 6, 6, 5, 5, 5, 5, 5, 5],
  left:       [1, 1, 1, 1, 2, 3, 4, 5, 6],
  boxWidth:   [3, 3, 4, 5, 5, 4, 4, 4, 4],
  boxHeight:  [2, 2, 2, 1, 1, 1, 1, 1, 1],
};
//bps:               [1, 2, 3, 4, 5, 6, 7, 8, 9]
const commonTop1 =   [7, 7, 7, 7, 7, 7, 7, 7, 7];
const commonTop2 =   [8, 8, 8, 7, 7, 7, 7, 7, 7];
export const list = {
  box1: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        commonTop1,
    left:       [1, 1, 1, 1, 2, 2, 3, 4, 5],
  },
  box2: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        commonTop1,
    left:       [2, 2, 2, 2, 3, 3, 4, 5, 6],
  },
  box3: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        commonTop1,
    left:       [3, 3, 3, 3, 4, 4, 5, 6, 7],
  },
  box4: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        commonTop2,
    left:       [1, 1, 1, 4, 5, 5, 6, 7, 8],
  },
  box5: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        commonTop2,
    left:       [2, 2, 2, 5, 6, 6, 7, 8, 9],
  },
  box6: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        commonTop2,
    left:       [3, 3, 3, 6, 7, 7, 8, 9, 10],
  },
};
