import React from 'react';
import styled from 'styled-components';
import { colors } from '../../helper/Variables';

const Burger = props => {
  let classes = '';
  const renderClasses = () => {
    if (props.darkTheme && !props.isOpen) {
      classes = classes + ' darkTheme';
    }
    if (props.isOpen) {
      classes = classes + ' isOpen';
    }
    return classes;
  };
  return (
    <BurgerContainer onClick={() => props.toggleMenu()}>
      <BurgerMenu className={renderClasses()}>
        <div className={'BurgerBar'} />
        <div className={'BurgerBar'} />
        <div className={'BurgerBar'} />
      </BurgerMenu>
    </BurgerContainer>
  );
};

const BurgerContainer = styled.div`
  cursor: pointer;
  display: block;
`;
const BurgerMenu = styled.div`
  position: relative;
  display: block;
  width: 20px;
  height: 18px;

  > .BurgerBar {
    width: 20px;
    height: 2px;
    display: block;
    background-color: ${colors.black};
    &:first-child {
      transition: transform 0.4s ease, background-color 0.3s ease;
      transform-origin: 1px 1px;
      transform: rotate(0deg);
      margin-bottom: 5px;
    }
    &:nth-child(2) {
      transition: opacity 0.3s ease 0.2s, background-color 0.3s ease;
      opacity: 1;
      margin-bottom: 5px;
    }
    &:last-child {
      transform-origin: 5px 3px;
      margin-bottom: 0;
      transition: transform 0.4s ease, background-color 0.3s ease;
      transform: rotate(0deg);
    }
  }
  &.darkTheme {
    > .BurgerBar {
      background-color: ${colors.white};
    }
  }
  &.isOpen {
    > .BurgerBar {
      &:first-child {
        transform: rotate(45deg) translate(3px, 0px);
      }
      &:nth-child(2) {
        transition: opacity 0.2s ease;
        opacity: 0;
      }
      &:last-child {
        transform: rotate(-45deg) translate(3px, 0px);
      }
    }
  }
`;

export default Burger;
