import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../helper/Variables';
import { Heading2, Text } from '../../helper/Typography';
import { GridBox, GridSection } from '../Grid';
import { headerContentHeight, header } from './layouts';
import { SimpleImage } from '../../helper/StyledHelper';
import LanguageSwitch from './LanguageSwitch';
import { getUrlFromId } from '../../helper/helperServices';
import { routes } from '../../setup/routes';

const Menu = ({ general, isOpen, toggleMenu, lang, pathname }) => {
  // remove articles from here, once we have content
  const excludedLinks = ['imprint', 'dataProtection', 'articles'];
  return (
    <MenuContent isOpen={isOpen}>
      <GridSection gridRows={headerContentHeight}>
        <GridBox layout={header.menulist} style={{ alignContent: 'start' }}>
          <ListContainer>
            {routes[0].routes.map(
              route =>
                !excludedLinks.includes(route.id) && (
                  <Link
                    key={`${lang}-${route.id}`}
                    to={getUrlFromId(lang, route.id)}
                    activeClassName={'main-menu-active'}
                    onClick={() => {
                      toggleMenu();
                      window.scrollTo({
                        top: 0,
                      });
                    }}
                  >
                    <Heading2 as={'span'}>
                      {general.pageTitles[route.id]}
                    </Heading2>
                  </Link>
                )
            )}
            <LanguageSwitch
              className={'hideAbove-tablet1'}
              lang={lang}
              pathname={pathname}
              isVisible={isOpen}
            />
          </ListContainer>
        </GridBox>
        <GridBox layout={header.contact} className={'showAbove-tablet1'}>
          <ContactBlock>
            <Mail>
              <Text.small>{general.sayHello}</Text.small>
              <a href={'mailto:hello@neugelb.com'}>
                <Text.small className={'black'}>hello@neugelb.com</Text.small>
              </a>
            </Mail>
            <Social>
              <Text.small>{general.followUs}</Text.small>
              <SocialBox>
                <a
                  href={'https://www.facebook.com/neugelb/'}
                  target={'blank'}
                  rel="noopener noreferrer"
                >
                  <SimpleImage
                    src={'/assets/icons/social/Facebook-WhiteBg.svg'}
                  />
                </a>
                <a
                  href={'https://www.linkedin.com/company/neugelb/'}
                  target={'blank'}
                  rel="noopener noreferrer"
                >
                  <SimpleImage
                    src={'/assets/icons/social/Linkedin-WhiteBg.svg'}
                  />
                </a>
                <a
                  href={'https://www.instagram.com/neugelb/'}
                  target={'blank'}
                  rel="noopener noreferrer"
                >
                  <SimpleImage
                    src={'/assets/icons/social/Instagram-WhiteBg.svg'}
                  />
                </a>
                <a
                  href={'https://www.xing.com/companies/neugelbstudiosgmbh'}
                  target={'blank'}
                  rel="noopener noreferrer"
                >
                  <SimpleImage src={'/assets/icons/social/xing-WhiteBg.svg'} />
                </a>
              </SocialBox>
            </Social>
          </ContactBlock>
        </GridBox>
        <GridBox layout={header.address} className={'showAbove-tablet1'}>
          <ContactBlock>
            <AddressBlock>
              <Text.small>Berlin</Text.small>
              <Text.small className={'black'}>
                Neugelb Studios GmbH
                <br />
                Zeughofstraße 20
                <br />
                10997 Berlin
              </Text.small>
            </AddressBlock>
            <AddressBlock>
              <Text.small>Frankfurt am Main</Text.small>
              <Text.small className={'black'}>
                Neugelb Studios GmbH
                <br />
                Mainzer Landstraße 123
                <br />
                60327 Frankfurt a.M.
              </Text.small>
            </AddressBlock>
          </ContactBlock>
        </GridBox>
      </GridSection>
    </MenuContent>
  );
};

export default Menu;

const MenuContent = styled.div`
  @media (max-width: 767px) {
    padding-top: 112px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 120px;
  }
  /* @media (min-width: 1280px) {
    padding-top: 160px;
  } */
  background-color: ${colors.white};
  position: fixed;
  top: -100vh;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  left: 0;
  right: 0;
  z-index: 100;
  transition: transform 0.4s ease;
  transform: translateY(${({ isOpen }) => (isOpen ? '100%' : 0)});
  overflow: hidden;

  a {
    display: block;
    color: black;
  }
  a span {
    position: relative;
    display: inline-block;
    color: black;
  }

  a span:after {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    border-bottom: 3px solid #000000;
    top: 55%;
    left: 0;
    right: 0;
    transition: width 0.5s ease;
    color: black;
  }
  a:hover span:after {
    width: 100%;
  }
`;

const ListContainer = styled.div`
  align-self: center;
  justify-self: center;
  text-align: center;

  @media (max-width: 1023px) {
    height: calc(100vh - (112px * 2));
    position: relative;
    > span:first-child {
      margin-top: calc((100vh - 180px * 2 - 75px * 4) / 2);
    }
  }
  @media (max-width: 767px) {
    > span:first-child {
      margin-top: calc((100vh - 140px * 2 - 60px * 4) / 2);
    }
  }
  @media (min-width: 1024px) {
    justify-self: start;
    text-align: left;
  }
`;

const ContactBlock = styled.div`
  small {
    display: block;
    margin-bottom: 20px;
    &.black {
      color: #000000;
    }
  }
`;

const AddressBlock = styled.div`
  margin-bottom: 80px;

  small {
    display: block;
    margin-bottom: 20px;
    &.black {
      color: #000000;
    }
  }
`;

const Mail = styled.div`
  margin-bottom: 50px;
`;
const Social = styled.div`
  margin-bottom: 50px;
  a {
    display: inline-block;
    height: 24px;
    margin: 0 20px 0 0;
  }
`;

const SocialBox = styled.div`
  img {
    height: 24px;
    cursor: pointer;
  }
`;
