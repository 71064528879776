import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { GridSection, GridBox } from '../Grid';
import { sectionHeight, contentSection, creditSection } from './layouts';
import { Heading2, Text } from '../../helper/Typography';
import { ButtonFilled } from '../../helper/StyledHelper';
import { VideoOverlay } from '../VideoOverlay/VideoOverlay';
import { LottieContainer } from '../Lottie/LottieContainer';
import { PlayIcon } from '../../helper/StyledHelper';
import { Picture } from '../Picture';
import { AssetAccessor } from '@lws/react-components';

interface HDAVideoPlayerProps {
  text: any;
  image?: AssetAccessor;
  animation: any;
  darkTheme: boolean;
  labelTexts: any;
  scrollTop: number;
}

const HDAVideoPlayer = ({
  text,
  animation,
  darkTheme,
  labelTexts,
  scrollTop,
  image,
}: HDAVideoPlayerProps) => {
  return (
    <div>
      <GridSection
        gridRows={
          !text.comingSoon
            ? sectionHeight.contentSectionExtended
            : sectionHeight.contentSection
        }
        className="Dark"
      >
        <GridBox
          layout={
            !text.comingSoon
              ? contentSection.animationExtended
              : contentSection.animation
          }
          translateTop={[0.5, 0.5, 0.5, 0]}
        >
          {/* <LottieContainer
            animation={animation}
            isParallax={true}
            scrollTop={scrollTop}
          /> */}
          {image && (
            <Picture
              data={image}
              alt={text.image.alt}
              title={text.image.title}
            />
          )}
        </GridBox>
        <GridBox layout={contentSection.text} translateTop={[0, 0, 0, 0.5]}>
          <Text.pGrey>
            {text.comingSoon ? 'Coming Soon' : text.hashtag}
          </Text.pGrey>
          <Heading2>{text.headline}</Heading2>

          {!text.comingSoon && (
            <VideoOverlay
              playButton={
                <ButtonFilled
                  darkTheme={darkTheme}
                  style={{ marginTop: '10px', paddingLeft: '60px' }}
                >
                  <PlayIcon
                    src={
                      darkTheme
                        ? '/assets/icons/play-icon-black.svg'
                        : '/assets/icons/play-icon-white.svg'
                    }
                  />
                  <span>{labelTexts.playVideo}</span>
                </ButtonFilled>
              }
              videoId={text.videoId}
            />
          )}
        </GridBox>
      </GridSection>
      <GridSection
        gridRows={sectionHeight.creditSection}
        className="showAbove-mobile3 Dark"
      >
        <GridBox layout={creditSection} translateTop={0.5}>
          <Text.p style={{ margin: 0 }}>
            <Text.pBold darkTheme={darkTheme}>
              {labelTexts.visualDesign}{' '}
            </Text.pBold>
            {text.designers}
            <br />
            <Text.pBold darkTheme={darkTheme}>
              {labelTexts.students}{' '}
            </Text.pBold>
            {text.students}
          </Text.p>
        </GridBox>
      </GridSection>
    </div>
  );
};

export default HDAVideoPlayer;
