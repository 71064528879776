import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Text, Heading2 } from '../../helper/Typography';
import { Button } from '../../helper/StyledHelper';

export const Teaser = ({
  gridLayout,
  category,
  label,
  title,
  texts,
  button,
  linkTo,
  column,
  darkTheme,
}) => (
  <Flex column={column}>
    <Left column={column}>
      {label && <Text.pGrey>{label}</Text.pGrey>}
      <Heading2 darkTheme={darkTheme}>{title}</Heading2>
      {!column && button && (
        <Link
          to={linkTo}
          state={{
            jobCat: category === 'Office management' ? 'Operations' : category,
          }}
        >
          <Button
            className={'showAbove-tablet2'}
            style={{ marginTop: '12px' }}
            onClick={() =>
              window.scrollTo({
                top: 0,
              })
            }
          >
            {button}
          </Button>
        </Link>
      )}
    </Left>
    <Right column={column}>
      {texts &&
        Array.isArray(texts) &&
        texts.map((text, idx) => (
          <Text.p darkTheme={darkTheme} key={`teaser-text-${idx}`}>
            {text}
          </Text.p>
        ))}
      {texts && typeof texts === 'string' && (
        <Text.p darkTheme={darkTheme}>{texts}</Text.p>
      )}
      {column
        ? button && (
            <Link
              state={{
                jobCat:
                  category === 'Office management' ? 'Operations' : category,
              }}
              to={linkTo}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                })
              }
            >
              <Button
                darkTheme={darkTheme}
                style={{ marginTop: '12px' }}
                // className={'showAbove-tablet2'}
              >
                {button}
              </Button>
            </Link>
          )
        : button && (
            <Link
              state={{
                jobCat:
                  category === 'Office management' ? 'Operations' : category,
              }}
              to={linkTo}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                })
              }
            >
              <Button
                darkTheme={darkTheme}
                className={'hideAbove-tablet2'}
                style={{ marginTop: '12px' }}
              >
                {button}
              </Button>
            </Link>
          )}
    </Right>
  </Flex>
);

const Flex = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  -webkit-justify-content: space-between;
  justify-content: space-between;
  @media (max-width: 1278px) {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
`;

const Left = styled.div`
  width: ${({ column }) => (column ? '100%' : '85%')};

  @media (max-width: 1278px) {
  }
`;

const Right = styled.div`
  margin-top: ${({ column }) => (column ? 0 : '66px')};
  margin-left: ${({ column }) => (column ? 0 : '15%')};
  button {
    margin-top: ${({ column }) => (column ? 0 : '40px')};
  }
  @media (max-width: 1278px) {
    margin: 0;
  }
`;
