import React from 'react';

export const LineBreakRenderer = ({ text }: { text: string | string[] }) => {
  return Array.isArray(text) ? (
    <>
      {text.map((fragment: string, idx: number) => (
        <span key={fragment}>
          {fragment} <br />
        </span>
      ))}
    </>
  ) : text ? (
    <>{text}</>
  ) : (
    <></>
  );
};

export default LineBreakRenderer;
