import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getUrlFromId } from '../../helper/helperServices';

const Logo = props => (
  <LogoContainer onClick={() => props.closeMenu()} isDarkBG={props.darkTheme}>
    <BlockNavLink
      to={getUrlFromId(props.lang, 'home')}
      onClick={() => {
        window.scrollTo({
          top: 0,
        });
      }}
    />
  </LogoContainer>
);

const LogoContainer = styled.div`
  background-image: url("/assets/icons/header/logo-${props =>
    props.isDarkBG ? 'white' : 'black'}.svg");
  background-size: contain;
  transition: all 0.5s ease;
  background-repeat: no-repeat;
`;
const BlockNavLink = styled(Link)`
  display: block;
  height: 32px;
  width: 38px;
`;

export default Logo;
