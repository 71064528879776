export interface PageData {
  locale: string;
  type: string;
  slug: string;
}

// generates pages for case studies
export const pageData: PageData[] = [
  {
    locale: 'de',
    type: 'caseStudy',
    slug: 'design-case-study-hda',
  },
  {
    locale: 'en-US',
    type: 'caseStudy',
    slug: 'design-case-study-hda',
  },
  /* {
    locale: 'de',
    type: 'caseStudy',
    slug: 'human-banking',
  },
  {
    locale: 'en-US',
    type: 'caseStudy',
    slug: 'human-banking',
  }, */
  // comment in to generate pages for article
  /* {
    locale: 'de',
    type: 'article',
    slug: 'data-analysis',
  },
  {
    locale: 'en-US',
    type: 'article',
    slug: 'data-analysis',
  }, */
];
