import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { translateRoute } from '../../helper/helperServices';

const LanguageSwitch = props => (
  <LanguageSwitchContainer isVisible={props.isVisible} {...props}>
    {props.pathname && (
      <Link to={switchLanguage(props.pathname) || '/'}>
        <span>{props.lang === 'de' ? 'EN' : 'DE'}</span>
      </Link>
    )}
  </LanguageSwitchContainer>
);

const switchLanguage = pathname => {
  if (!pathname) return;
  return translateRoute(pathname);
};

const LanguageSwitchContainer = styled.div`
  cursor: pointer;
  opacity: ${props => (props.isVisible ? '1' : '0')};
  transition: opacity 0.3s;
  line-height: 1;
  @media (max-width: 1023px) {
    bottom: 0;
    position: absolute;
    left: calc(50% - 12px);
  }
  span {
    font-family: 'Metric-Regular', sans-serif;
    display: block;
    color: black;
  }
`;

export default LanguageSwitch;
