import React, { Component } from 'react';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import LanguageSwitch from './LanguageSwitch';
import Logo from './Logo';
import Burger from './Burger';
import Menu from './Menu';
import ScrollNav from './ScrollNav';
import { toUrlPath } from '../../helper/helperServices';

class Header extends Component {
  constructor(props) {
    super(props);
    this.setOverflow = this.setOverflow.bind(this);
  }
  state = {
    isOpen: false,
  };
  componentDidMount() {
    // Needed this because we are leaving the route after opening the menu
    document
      .getElementsByTagName('html')[0]
      .classList.remove('overflow-hidden');
  }
  toggleMenu() {
    this.state.isOpen ? this.closeMenu() : this.openMenu();
  }
  openMenu() {
    this.setOverflow(true);
    this.setState({
      isOpen: true,
    });
  }
  closeMenu() {
    this.setOverflow(false);
    this.setState({
      isOpen: false,
    });
  }
  setOverflow(hidden) {
    hidden
      ? document
          .getElementsByTagName('html')[0]
          .classList.add('overflow-hidden')
      : document
          .getElementsByTagName('html')[0]
          .classList.remove('overflow-hidden');
  }

  render() {
    const {
      darkTheme,
      lang,
      generalTexts,
      pathname,
      navigationItems,
      activeHashLink,
      scrollNavigationRef,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <Headroom style={{ zIndex: '9999' }}>
        <HeaderContainer darkTheme={darkTheme && !isOpen}>
          <FixedIconLeft>
            <Logo
              lang={lang}
              closeMenu={() => this.closeMenu()}
              darkTheme={darkTheme && !isOpen}
            />
          </FixedIconLeft>
          {navigationItems && (
            <ScrollNav
              darkTheme={darkTheme}
              scrollNavigationRef={scrollNavigationRef}
              navigationItems={navigationItems}
              isMenuOpen={!isOpen}
              activeHashLink={activeHashLink}
              setOverflow={this.setOverflow}
            />
          )}
          <FixedIcon className={'language-switch'}>
            <LanguageSwitch
              lang={toUrlPath(lang)}
              pathname={pathname}
              isVisible={isOpen}
            />
          </FixedIcon>
          <FixedIconRight>
            <Burger
              toggleMenu={() => this.toggleMenu()}
              darkTheme={darkTheme && !isOpen}
              isOpen={isOpen}
            />
          </FixedIconRight>
          <Menu
            pathname={pathname}
            isOpen={isOpen}
            toggleMenu={() => this.toggleMenu()}
            lang={lang}
            isVisible={isOpen}
            general={generalTexts}
          />
        </HeaderContainer>
      </Headroom>
    );
  }
}

export default Header;
export const headerBarHeight_D = '80px';

const HeaderContainer = styled.div`
  text-align: center;
  background-color: ${({ darkTheme }) => (darkTheme ? 'black' : 'white')};
  transition: background-color 0.5s ease;
  width: 100vw;
  height: 80px;
  z-index: 9999;
  display: flex;
  align-items: center;
`;

const FixedIcon = styled.div`
  top: 0;
  position: absolute;
  height: ${headerBarHeight_D};
  width: ${headerBarHeight_D};
  z-index: 300;
  > * {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  &.language-switch {
    @media (max-width: 1023px) {
      display: none;
    }
    @media (min-width: 1024px) {
      right: 120px;
    }
  }
`;

const FixedIconLeft = styled(FixedIcon)`
  left: 0;
  z-index: 1002;
  @media (min-width: 1024px) {
    left: 40px;
  }
`;
const FixedIconRight = styled(FixedIcon)`
  right: 0;
  z-index: 998;
  @media (min-width: 1024px) {
    right: 40px;
  }
`;
