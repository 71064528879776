import { Component } from 'react';
import { LocationObject } from './component-types';
import { bps } from '../helper/Variables';
import { throttle } from '../helper/helperServices';

export interface EntryPageProps {
  location: LocationObject;
  pageContext: {
    generalTexts: any;
    routeTexts: any;
    texts?: any;
    locale: string;
    id: string;
  };
}

export default class PageEnhancer extends Component<
  { [x: string]: any },
  { [x: string]: any }
> {
  timeout?: number;

  setAnimationState = (
    animationName: string,
    animationState: number | string | undefined
  ) => {
    return this.setState({
      [animationName]: animationState,
    });
  };

  setInitialAnimationsState(animationNames: string[]) {
    const state = animationNames.reduce(
      (state: any, name: string): { [x: string]: number } => ({
        ...state,
        [name]: 0,
      }),
      {}
    );
    this.setState({ ...state });
  }

  componentDidMount() {
    this.setDarkTheme();
    this.addEventListeners();
    this.updateScreenSize();
  }

  // call this Method if you are overwriting componentDidMount method in your child class
  callSuperComponentDidMountMethods = () => {
    this.setDarkTheme();
    this.addEventListeners();
    this.updateScreenSize();
  };

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addEventListeners = () => {
    // register all listeners
    window.addEventListener('resize', this.throttleUpdateScreensize);
    // listen to custom event 'internal.active-anchor-change' triggered by the Anchor component
    // To use this functionality, define a state object "darkSections" with an array of strings in your component
    window.addEventListener('internal.active-anchor-change', this.setDarkTheme);
  };

  removeEventListeners = () => {
    // remove all listeners
    window.removeEventListener('resize', this.throttleUpdateScreensize);
    window.removeEventListener(
      'internal.active-anchor-change',
      this.setDarkTheme
    );
  };

  // throttle events and call ref function
  throttleUpdateScreensize = () => {
    throttle(this.timeout, this.updateScreenSize, 500);
  };

  getInitalBp = () => {
    return this.state.activeBreakpoint;
  };

  // update the isScreenDesktop state (used e.g. for sprite animations)
  updateScreenSize = () => {
    const { activeBreakpoint } = this.state;
    const currentWindowWidth =
      typeof window !== 'undefined' && window.innerWidth;
    if (this.state.isScreenDesktop !== window.innerWidth > 1023) {
      this.setState({ isScreenDesktop: window.innerWidth > 1023 });
    }
    // write current bp object to state when update needed
    if (
      !activeBreakpoint ||
      currentWindowWidth < activeBreakpoint.min ||
      currentWindowWidth > activeBreakpoint.max
    ) {
      this.setState({
        activeBreakpoint: bps.filter(
          (bp: any) =>
            currentWindowWidth >= bp.min && currentWindowWidth <= bp.max
        )[0],
      });
    }
  };

  // setState darkTheme if needed
  setDarkTheme = () => {
    const darkSections: string[] = this.state.darkSections;
    const hash: string = window.location.hash.split('#')[1];
    if (hash) {
      // use this to not overwrite initiall darkTheme before scrolling
      const darkTheme: boolean =
        darkSections &&
        darkSections.length > 0 &&
        darkSections.indexOf(hash) > -1;
      this.state.darkTheme !== darkTheme &&
        this.setState({ darkTheme: darkTheme });
    }
  };
}
