export const routes = [
  {
    locale: 'de',
    routes: [
      {
        url: 'home',
        id: 'home',
      },
      {
        url: 'ueber-uns',
        id: 'aboutUs',
      },
      {
        url: 'team-und-karriere',
        id: 'jobs',
      },
      {
        url: 'case-studies',
        id: 'caseStudies',
      },
      {
        url: 'kontakt',
        id: 'contact',
      },
      {
        url: 'impressum',
        id: 'imprint',
      },
      {
        url: 'datenschutz',
        id: 'dataProtection',
      },
      {
        url: 'artikel',
        id: 'articles',
      },
    ],
  },
  {
    locale: 'en',
    routes: [
      {
        url: 'home',
        id: 'home',
      },
      {
        url: 'about-us',
        id: 'aboutUs',
      },
      {
        url: 'team-and-career',
        id: 'jobs',
      },
      {
        url: 'case-studies',
        id: 'caseStudies',
      },
      {
        url: 'contact',
        id: 'contact',
      },
      {
        url: 'imprint',
        id: 'imprint',
      },
      {
        url: 'data-protection',
        id: 'dataProtection',
      },
      {
        url: 'articles',
        id: 'articles',
      },
    ],
  },
];

export const translatedRoutes = {
  '/en/home': '/de/home',
  '/en/about-us': '/de/ueber-uns',
  '/en/team-and-career': '/de/team-und-karriere',
  '/en/contact': '/de/kontakt',
  '/en/imprint': '/de/impressum',
  '/en/data-protection': '/de/datenschutz',
  '/en/case-studies': '/de/case-studies',
  '/en/articles': '/de/artikel',
  '/en/404': '/de/404',
};
