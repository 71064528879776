import React from 'react';
import styled, { css } from 'styled-components';
import { bps } from '../../helper/Variables';

export const Image = ({ dark, imageFolderUrl, fileExtension, ...props }) => {
  return (
    <ImageContainer
      {...props}
      dark={dark}
      imageFolderUrl={imageFolderUrl}
      fileExtension={fileExtension || 'jpg'}
    />
  );
};

const densities = [72, 200, 300];

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  transition: all 0.5s ease;
  background-position: center;
  background-size: cover;
  ${props =>
    bps.map(bp => {
      return densities.map((dens, idx) => {
        return css`
          @media (max-width: ${bp.max}px) and (min-width: ${
          bp.min
        }px) and (min-resolution: ${dens}dpi) and (max-resolution: ${densities[
          idx + 1
        ] - 1 || 1000}dpi), (max-width: ${bp.max}px) and (min-width: ${
          bp.min
        }px) and (-webkit-min-device-pixel-ratio: ${idx + 1}){
            background-image: url("${props.imageFolderUrl}/Grid-${
          bp.baseUnit === 100 ? 112 : bp.baseUnit
        }/@${idx + 1}x.${props.fileExtension}") ;
          }
        `;
      });
    })}
`;
