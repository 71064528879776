import * as React from 'react';
import styled, { css } from 'styled-components';
import { scrollHelper } from '../../helper/scroll-helper';

interface ScrollNavProps {
  navigationItems: { hash: string; name: string };
  texts: any;
  activeHashLink: string;
  scrollNavigationRef: any;
  isMenuOpen: boolean;
  setOverflow: Function;
  darkTheme: boolean;
  lang: string;
}
interface ScrollNavState {
  activeHashLink: string;
  showScrollNav: boolean;
  isMenuOpen: boolean;
}
export default class ScrollNav extends React.Component<
  ScrollNavProps,
  ScrollNavState
> {
  timeout: undefined;
  constructor(props: ScrollNavProps) {
    super(props);
    this.timeout = undefined;
    this.state = {
      showScrollNav: false,
      activeHashLink: '',
      isMenuOpen: false,
    };
  }
  componentDidMount() {
    const firstKey = Object.keys(this.props.navigationItems)[0];
    this.setState({
      activeHashLink: this.props.navigationItems[firstKey].hash,
    });

    window.addEventListener(
      'internal.active-anchor-change',
      this.setActiveHash
    );
    this.setActiveHash();
  }

  componentWillUnmount() {
    window.removeEventListener(
      'internal.active-anchor-change',
      this.setActiveHash
    );
  }

  setActiveHash = () => {
    const hash = window.location.hash.replace('#', '');
    if (hash !== this.state.activeHashLink)
      this.setState({ activeHashLink: hash });
  };

  setShowScrollNav = () => {
    if (
      !this.state.showScrollNav &&
      this.props.scrollNavigationRef &&
      this.props.scrollNavigationRef.current &&
      this.props.scrollNavigationRef.current.getBoundingClientRect().top <= 0
    ) {
      // was set to false and needs to be changed to be true
      this.setState({ showScrollNav: true });
    } else if (
      !!this.state.showScrollNav &&
      this.props.scrollNavigationRef &&
      this.props.scrollNavigationRef.current &&
      this.props.scrollNavigationRef.current.getBoundingClientRect().top > 0
    ) {
      // was set to true and needs to be changed to be false
      this.setState({ showScrollNav: false });
    }
  };
  render() {
    const {
      scrollNavigationRef,
      navigationItems,
      setOverflow,
      isMenuOpen,
      darkTheme,
    } = this.props;
    const { showScrollNav, activeHashLink } = this.state;
    return (
      <>
        {/* ========== desktop */}
        <LinkList
          showScrollNav={
            (scrollNavigationRef && showScrollNav) || !scrollNavigationRef
          }
        >
          {Object.keys(navigationItems).map((key: string) => (
            <ScrollLink
              key={navigationItems[key].hash}
              onClick={e =>
                scrollHelper.scrollSmoothlyTo(e, navigationItems[key].hash, 80)
              }
              darkTheme={darkTheme}
            >
              <StrikethroughText
                isDesktop={true}
                active={navigationItems[key].hash === activeHashLink}
                darkTheme={darkTheme}
              >
                {navigationItems[key].name}
              </StrikethroughText>
            </ScrollLink>
          ))}
        </LinkList>
        {/* ========== mobile */}
        <StyledDropdown
          isMenuOpen={this.state.isMenuOpen}
          onClick={() => {
            this.setState({ isMenuOpen: !this.state.isMenuOpen });
            setOverflow(!this.state.isMenuOpen);
          }}
          darkTheme={darkTheme}
          showScrollNav={(isMenuOpen && showScrollNav) || !scrollNavigationRef}
        >
          {this.state.isMenuOpen ? 'close' : activeHashLink}
        </StyledDropdown>
        <LinksMenu isMenuOpen={this.state.isMenuOpen}>
          {Object.keys(navigationItems).map((key: string) => (
            <div key={navigationItems[key].hash}>
              <StrikethroughText
                isDesktop={false}
                active={navigationItems[key].hash === activeHashLink}
                onClick={(e: any) => {
                  this.setState({ isMenuOpen: false });
                  setOverflow(false);
                  scrollHelper.scrollSmoothlyTo(
                    e,
                    navigationItems[key].hash,
                    80
                  );
                }}
              >
                {navigationItems[key].name}
              </StrikethroughText>
            </div>
          ))}
        </LinksMenu>
      </>
    );
  }
}

const StyledDropdown = styled.div`
  display: ${({
    showScrollNav,
  }: {
    darkTheme: boolean;
    showScrollNav: boolean;
    isMenuOpen: boolean;
  }) => (showScrollNav ? 'inline-block' : 'none')};
  margin: 0 auto;
  text-transform: capitalize;
  cursor: pointer;
  z-index: 1001;
  color: ${({
    darkTheme,
  }: {
    darkTheme: boolean;
    showScrollNav: boolean;
    isMenuOpen: boolean;
  }) => (darkTheme ? 'white' : 'black')};
  @media (min-width: 768px) {
    display: none;
  }
`;

const LinksMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: ${({ isMenuOpen }: { isMenuOpen: boolean }) =>
    isMenuOpen ? '85px' : '0'};
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: ${({ isMenuOpen }: { isMenuOpen: boolean }) =>
    isMenuOpen ? '1000' : '0'};
  height: ${({ isMenuOpen }: { isMenuOpen: boolean }) =>
    isMenuOpen ? '100vh' : '0'};
  @media (min-width: 768px) {
    display: none;
  }
  transition: height 0.4s ease;
  div {
    display: ${({ isMenuOpen }: { isMenuOpen: boolean }) =>
      isMenuOpen ? 'block' : 'none'};
    padding: 10px 0;
  }
`;

const LinkList = styled.ul`
  display: inline-block;
  width: auto;
  list-style: none;
  height: 100%;
  margin: auto;
  padding: 0;
  transform: ${({ showScrollNav }: { showScrollNav: boolean }) =>
    !!showScrollNav ? 'translateY(0)' : 'translateY(-100%)'};
  transition: transform 0.35s ease;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ScrollLink = styled.li`
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
  font-family: 'Metric-Semibold', sans-serif;
  padding: 22px 40px;
  transition: background-color 0.15s ease;
  color: ${({ darkTheme }: { darkTheme: boolean }) =>
    darkTheme ? 'white' : 'black'};
  margin: auto;
  width: auto;
`;

const StrikethroughText = styled.span`
  position: relative;
  display: inline-block;
  margin: auto;
  &:after {
    content: '';
    width: ${({ active }: { isDesktop: boolean; active: boolean }) =>
      active ? '100%' : 0};
    height: 2px;
    position: absolute;
    border-bottom: 3px solid;
    top: 46%;
    left: 0;
    right: 0;
    transition: width 0.5s ease;
  }
  &:hover {
    ${({
      isDesktop,
    }: {
      isDesktop: boolean;
      active: boolean;
      darkTheme: boolean;
    }) =>
      !isDesktop &&
      css`
        &:after {
          width: 100%;
        }
      `}
  }
  cursor: pointer;
`;
