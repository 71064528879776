import { Component } from 'react';
import { throttle } from '../helper/helperServices';
import React from 'react';

interface ScrollPositionObserverProps {
  children: React.ReactElement | React.ReactElement[];
}
interface ScrollPositionObserverState {
  scrollTop: number;
}

export default class ScrollPositionObserver extends Component<
  ScrollPositionObserverProps,
  ScrollPositionObserverState
> {
  timeout: undefined;
  state = {
    scrollTop: 0,
  };
  componentDidMount() {
    window.addEventListener('scroll', this.watchScrollPosition);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.watchScrollPosition);
  }
  watchScrollPosition = () => {
    throttle(this.timeout, this.updateScrollTop, 20);
  };
  updateScrollTop = () => {
    const scrollTop = window.scrollY;
    this.setState({
      scrollTop: scrollTop,
    });
  };
  render() {
    return Array.isArray(this.props.children)
      ? this.props.children.map((child: React.ReactElement, i: number) => {
          return React.createElement(child.type, {
            key: `${child.type}-${i}`,
            scrollTop: this.state.scrollTop,
            ...child.props,
          });
        })
      : React.createElement(this.props.children.type, {
          scrollTop: this.state.scrollTop,
          ...this.props.children.props,
        });
  }
}
