export const sectionHeight = [6, 6, 6, 6, 4, 4, 4, 4, 4];

export const randomProfileLayout = {
  header: {
    top: [2, 2, 2, 2, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 3, 4, 3, 3, 3, 3, 3],
    boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  picture: {
    top: [4, 4, 4, 4, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
};

export const flagLayout = {
  top: [5, 5, 5, 5, 3, 3, 3, 3, 3],
  left: [3, 3, 4, 5, 8, 8, 9, 10, 11],
};

//the numeric key has to match with the patternConfiguration field set it up in contentful
export const patternConfigurations = {
  // atm pattern 1 is the only one design specified
  1: {
    shape1: {
      top: [5, 5, 5, 5, 3, 3, 3, 3, 3],
      left: [3, 3, 4, 4, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },

    shape2: {
      top: [6, 6, 6, 6, 4, 4, 4, 4, 4],
      left: [1, 1, 3, 4, 7, 7, 8, 9, 10],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
  },
  2: {
    shape1: {
      top: [5, 5, 5, 3, 3, 3, 3, 3, 3],
      left: [3, 3, 4, 7, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
    shape2: {
      top: [6, 6, 6, 4, 4, 4, 4, 4, 4],
      left: [1, 1, 3, 6, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
  },
  3: {
    shape1: {
      top: [5, 5, 5, 3, 3, 3, 3, 3, 3],
      left: [3, 3, 4, 7, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
    shape2: {
      top: [6, 6, 6, 4, 4, 4, 4, 4, 4],
      left: [1, 1, 3, 6, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
  },
  4: {
    shape1: {
      top: [5, 5, 5, 3, 3, 3, 3, 3, 3],
      left: [3, 3, 4, 7, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
    shape2: {
      top: [6, 6, 6, 4, 4, 4, 4, 4, 4],
      left: [1, 1, 3, 6, 8, 8, 9, 10, 11],
      boxWidth: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    },
  },
};
