import styled from 'styled-components';
import { colors } from './Variables';

export const Button = styled.button`
  position: relative;
  font-family: 'Metric-Regular', sans-serif;
  line-height: 1;
  padding: 15px 24px 17px 24px;
  font-size: 16px;
  border-radius: 100px;
  text-align: center;
  box-shadow: 0 0 1px 0 ${colors.white} inset, 0 0 1px 0px ${colors.white};
  border: 1px solid
    ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
  color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
  min-width: 120px;
  width: fit-content;
  height: 56px;
  box-sizing: border-box;
  background: transparent;
  overflow: hidden;

  transition: 0.3s all ease;

  &:not([disabled]) {
    cursor: pointer;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.2;
    pointer-events: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${({ darkTheme }) =>
      darkTheme ? colors.white : colors.black};
    color: ${({ darkTheme }) => (darkTheme ? colors.black : colors.white)};
  }
`;
export const ButtonFilled = styled(Button)`
  background-color: ${({ darkTheme }) =>
    darkTheme ? colors.white : colors.black};
  color: ${({ darkTheme }) => (darkTheme ? colors.black : colors.white)};
  position: relative;
  span {
    top: calc(50% - 12px);
  }
`;

export const SimpleImage = styled.img`
  height: ${({ overflowDirection }) =>
    overflowDirection === 'vertical' ? 'auto' : '100%'};
  width: ${({ overflowDirection }) =>
    overflowDirection !== 'vertical' ? 'auto' : '100%'};
`;

export const OverlayWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const PlayerWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ showPlayer }) => (showPlayer ? 1 : 0)};
  opacity: ${({ showPlayer }) => (showPlayer ? 1 : 0)};
  -webkit-transition: opacity 1500ms ease-out;
  -moz-transition: opacity 1500ms ease-out;
  -o-transition: opacity 1500ms ease-out;
  transition: opacity 1500ms ease-out;
`;

export const PlayButtonWrapper = styled.div`
  position: relative;
`;

export const PlayButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
`;

export const PlayIcon = styled(SimpleImage)`
  height: 28px;
  position: absolute;
  top: calc(50% - 15px);
  left: 22px;
`;

export const SharpImageContainer = styled.div`
  max-width: 100%;
`;

export const SocialIconsWrapper = styled.div`
  margin: 14px 0 0 20px;
  @media (max-width: 768px) {
    margin: -14px 0 0 0;
  }
`;
