import React from 'react';
import styled, { css } from 'styled-components';
import { bps, colors } from '../helper/Variables';

interface GridSectionProps {
  gridRows: number[];
  children?: React.ReactNode | HTMLElement;
  dark?: boolean;
  // provide a number for all breakpoints or an array of number s for
  // [  <= 320px,
  //    321px - 767px
  //    768px - 1279px
  //    >= 1280px ]
  translateTop?: number | number[];
  className?: string;
  style?: React.CSSProperties;
}

export interface LayoutObject {
  top: number[];
  left: number[];
  boxWidth?: number[];
  boxHeight?: number[];
}

export const GridSection = ({
  gridRows,
  dark = false,
  translateTop = 0,
  children,
  className,
  style,
}: GridSectionProps) => {
  return (
    <ContentArea
      dark={dark}
      translateTop={translateTop}
      className={className}
      style={style}
    >
      <Section>
        <GridContaier>
          <GridContent gridRows={gridRows}>{children}</GridContent>
        </GridContaier>
      </Section>
    </ContentArea>
  );
};

export const ContentArea = styled.div`
  text-align: center;
  position: relative;
  line-height: 0;

  ${({ translateTop }: { dark: boolean; translateTop: number | number[] }) =>
    translateTop &&
    css`
      margin-top: ${typeof translateTop === 'number'
        ? `${translateTop * 100}px`
        : `${translateTop[0] * 100}px`};
      @media (min-width: 321px) {
        margin-top: ${typeof translateTop === 'number'
          ? `${translateTop * 112}px`
          : `${translateTop[1] * 112}px`};
      }
      @media (min-width: 768px) {
        margin-top: ${typeof translateTop === 'number'
          ? `${translateTop * 120}px`
          : `${translateTop[2] * 120}px`};
      }
      @media (min-width: 1280px) {
        margin-top: ${typeof translateTop === 'number'
          ? `${translateTop * 160}px`
          : `${translateTop[3] * 160}px`};
      }
    `};
`;

export const Section = styled.section`
  width: auto;
  display: inline-block;
  margin: 0 auto;
  text-align: left;
`;

export const GridContaier = styled.div`
  display: grid;
  grid-template-rows: auto;
  transition: all 0.4s ease;
  ${() =>
    bps.map(bp => {
      return css`
        @media (max-width: ${bp.max}px) and (min-width: ${bp.min}px) {
          grid-template-columns: ${bp.baseUnit * bp.columnCount}px;
        }
      `;
    })}
`;

export const GridContent = styled.div`
  display: grid;
  transition: all 0.4s ease;
  ${({ gridRows }: { gridRows: number[] }) =>
    bps.map((bp, idx) => {
      return css`
        @media (max-width: ${bp.max}px) and (min-width: ${bp.min}px) {
          grid-template-columns: repeat(${bp.columnCount}, ${bp.baseUnit}px);
          grid-template-rows: repeat(${gridRows[idx]}, ${bp.baseUnit}px);
        }
      `;
    })}
`;

export const GridBox = styled.div`
  z-index: 10;
  // needed to give children the ability to layout themselves
  display: grid;
  ${({ layout }: { layout: LayoutObject; translateTop?: number | number[] }) =>
    generateMediaQueries(layout)}
  ${({
    translateTop,
  }: {
    layout: LayoutObject;
    translateTop?: number | number[];
  }) =>
    translateTop &&
    css`
      transform: translateY(
        ${typeof translateTop === 'number'
          ? `${translateTop * 100}px`
          : `${translateTop[0] * 100}px`}
      );
      @media (min-width: 321px) {
        transform: translateY(
          ${typeof translateTop === 'number'
            ? `${translateTop * 112}px`
            : `${translateTop[1] * 112}px`}
        );
      }
      @media (min-width: 768px) {
        transform: translateY(
          ${typeof translateTop === 'number'
            ? `${translateTop * 120}px`
            : `${translateTop[2] * 120}px`}
        );
      }
      @media (min-width: 1280px) {
        transform: translateY(
          ${typeof translateTop === 'number'
            ? `${translateTop * 160}px`
            : `${translateTop[3] * 160}px`}
        );
      }
    `};

  &:after {
    font-size: 0;
    position: absolute;
  }
`;

export const FullWidthElement = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @media (min-width: 1920px) {
    top: 0;
    left: calc((100vw - (10 * 160px)) / 2);
    right: calc((100vw - (10 * 160px)) / 2);
    bottom: 0;
  }
`;

export const BuddySliderBanner = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin-bottom: 32px;

  @media (min-width: 1920px) {
    top: 0;
    left: calc((100vw - (10 * 160px)) / 2);
    right: calc((100vw - (10 * 160px)) / 2);
    bottom: 0;
  }
`;

export const makeGridObject = (
  layoutObject: LayoutObject,
  idx: number
): any => {
  const isValid =
    typeof layoutObject.left[idx] !== 'undefined' &&
    typeof layoutObject.top[idx] !== 'undefined';
  if (!isValid)
    throw new Error('your layout does not contain 9 values for left or top!');
  return {
    'grid-column-start': layoutObject.left[idx],
    'grid-column-end': `span ${(layoutObject.boxWidth &&
      layoutObject.boxWidth[idx]) ||
      1}`,
    'grid-row-start': layoutObject.top[idx],
    'grid-row-end': `span ${(layoutObject.boxHeight &&
      layoutObject.boxHeight[idx]) ||
      1}`,
  };
};

export const generateMediaQueries = (layout: LayoutObject): string[] => {
  let min = 0;

  return bps.reduce((acc: string[], bp: any, idx: number) => {
    if (idx === bps.length - 1) {
      // don't iterate last layout set
      return acc;
    }

    const currentCSS = makeGridObject(layout, idx);
    let nextCSS = makeGridObject(layout, idx + 1);
    let isOneBeforelast = idx === bps.length - 2;

    if (
      Object.values(currentCSS).join('') === Object.values(nextCSS).join('')
    ) {
      if (isOneBeforelast) {
        acc.push(createCssQuery(bps[idx + 1].max, min, currentCSS, idx));
      }
    } else {
      // Object.values(currentCSS).join('') !== Object.values(nextCSS).join('')
      acc.push(createCssQuery(bp.max, min, currentCSS, idx));
      if (isOneBeforelast) {
        acc.push(
          createCssQuery(bps[idx + 1].max, bps[idx + 1].min, nextCSS, idx)
        );
      }
      min = bps[idx + 1].min;
    }
    return acc;
  }, []);
};

const createCssQuery = (
  max: number,
  min: number,
  cssObject: any,
  idx: number
): string => {
  return css`
    @media (max-width: ${max}px) and (min-width: ${min}px) {
      grid-column-start: ${cssObject['grid-column-start']};
      grid-column-end: ${cssObject['grid-column-end']};
      grid-row-start: ${cssObject['grid-row-start']};
      grid-row-end: ${cssObject['grid-row-end']};
      &:after{
        content: "BP ${idx + 1}: ${min} - ${max}";
      }
    }
  `.join('');
};
