export const colors = {
  white: '#ffffff',
  whiteGrey: '#FDFDFD',
  greyLighter2: '#F9F9F9',
  greyLighter: '#DFDFDF',
  grey: '#999999',
  greyDarkest: '#333333',
  black: '#000000',
  red: '#D0021B',
  turquoise: '#4BDDB6',
};

// used to simulate a GridBox sized elemet
export const gridBoxWidth = `
@media (max-width: 320px) {
  width: 100px;
}
@media (min-width: 321px) and (max-width: 767px) {
  width: 112x;
}
@media (min-width: 321px) and (max-width: 1279px) {
  width: 120px;
}
@media (min-width: 1280px) {
  width: 160px;
}
`;

export const bps = [
  {
    name: 'mobile1',
    min: 0,
    max: 320,
    baseUnit: 100,
    columnCount: 3,
  },
  {
    name: 'mobile2',
    min: 321,
    max: 479,
    baseUnit: 112,
    columnCount: 3,
  },
  {
    name: 'mobile3',
    min: 480,
    max: 767,
    baseUnit: 112,
    columnCount: 4,
  },
  {
    name: 'tablet1',
    min: 768,
    max: 1023,
    baseUnit: 120,
    columnCount: 6,
  },
  {
    name: 'tablet2',
    min: 1024,
    max: 1279,
    baseUnit: 120,
    columnCount: 8,
  },
  {
    name: 'desktop1',
    min: 1280,
    max: 1599,
    baseUnit: 160,
    columnCount: 8,
  },
  {
    name: 'desktop2',
    min: 1600,
    max: 1919,
    baseUnit: 160,
    columnCount: 10,
  },
  {
    name: 'desktop3',
    min: 1920,
    max: 2239,
    baseUnit: 160,
    columnCount: 12,
  },
  {
    name: 'desktop4',
    min: 2240,
    max: 20000,
    baseUnit: 160,
    columnCount: 14,
  },
];

export const bannerSectionHeight = [5, 5, 5, 5, 5, 5, 3, 3, 3];
