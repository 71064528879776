import React from 'react';
import styled, { css } from 'styled-components';
import { FluidObject } from 'gatsby-image';
import { default as GatsbyImage } from 'gatsby-image/withIEPolyfill';
import { AssetAccessor } from '@lws/react-components';
import { defaultFallbackImage } from '@lws/react-components/dist/accessors/util';

interface PictureProps {
  data: AssetAccessor;
  alt: string;
  children?: React.ReactNode | HTMLElement;
  className?: string;
  title?: string;
  asBackgroundImage?: boolean;
  imageWidth?: string;
  imageHeight?: string;
}

export const Picture = ({
  children,
  className,
  data,
  alt,
  title,
  imageWidth,
  imageHeight,
  asBackgroundImage = false,
}: PictureProps) => {
  const fluid: FluidObject = data.fluid({
    fallbackImage: defaultFallbackImage,
  }) as FluidObject;
  if (!asBackgroundImage && children) {
    console.warn(
      'Picture component: The Picture component needs to be set as background with "asBackgroundImage" prop, to be able to display children.'
    );
  }

  if (fluid) {
    return imageWidth || imageHeight ? (
      <GatsbyImageContainer imageWidth={imageWidth} imageHeight={imageHeight}>
        <GatsbyImage
          className={className}
          fluid={fluid}
          alt={alt}
          title={title}
          objectFit={asBackgroundImage ? 'cover' : 'contain'}
        />
        {asBackgroundImage && <ChildWrapper>{children}</ChildWrapper>}
      </GatsbyImageContainer>
    ) : (
      <React.Fragment>
        <GatsbyImage
          className={className}
          fluid={fluid}
          alt={alt}
          title={title}
          objectFit={asBackgroundImage ? 'cover' : 'contain'}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
        {asBackgroundImage && <ChildWrapper>{children}</ChildWrapper>}
      </React.Fragment>
    );
  } else return null;
};

// Evaluate if either width or height are controlled by props.
// Render a container accordingly, that controlls it's dimensions
// or extend the GBI to it's parant's dimensions.
const GatsbyImageContainer = styled.div`
  position: relative;
  ${({
    imageWidth,
    imageHeight,
  }: {
    imageWidth?: string;
    imageHeight?: string;
  }) => css`
    width: ${imageWidth || 'auto'};
    height: ${imageHeight || 'auto'};
  `}
`;
const ChildWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
`;
