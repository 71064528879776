import { Heading1, Text } from '../../helper/Typography';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../helper/Variables';
import { Button } from '../../helper/StyledHelper';
import SocialIcons from '../SocialIcons/SocialIcons';

export const Overlay = props => {
  const closeOverlay = () => {
    props.toggleOverlay();
  };
  const { contactForm } = props;
  return (
    <BlackOverlay
      isOpen={
        props.currentState === 'success' || props.currentState === 'failure'
      }
    >
      <ContentContainer>
        {props.currentState === 'success' ? (
          <>
            <Icon src={'/assets/icons/check.svg'} />
            <Heading1 as={'span'}>
              {
                contactForm[
                  props.requestType === 'application'
                    ? 'application'
                    : 'general'
                ].success.headline
              }
            </Heading1>
            <Text.p>
              {
                contactForm[
                  props.requestType === 'application'
                    ? 'application'
                    : 'general'
                ].success.message
              }
            </Text.p>
            <Button darkTheme={true} onClick={closeOverlay}>
              {contactForm.close}
            </Button>
          </>
        ) : (
          <>
            <Icon src={'/assets/icons/error.svg'} />
            <Heading1 as={'span'}>{contactForm.failure.headline}</Heading1>
            <Text.p>{contactForm.failure.message}</Text.p>
            <Button darkTheme={true} onClick={closeOverlay}>
              {contactForm.tryAgain}
            </Button>
          </>
        )}

        <SocialIcons dark />
      </ContentContainer>
    </BlackOverlay>
  );
};

const BlackOverlay = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 550;
  left: ${props => (props.isOpen ? 0 : '100%')};
  top: ${props => (props.isOpen ? 0 : '100%')};

  span,
  p {
    color: inherit;
  }

  .socialIcons {
    margin-top: 120px;
    width: 50px;
    > div {
      margin-left: 0;
      margin-right: 16px;
    }
  }
`;

const ContentContainer = styled.div`
  padding: 30px;
  max-width: 640px;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
`;
