import React from 'react';
import Lottie from 'react-lottie';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

interface LottieContainerProps {
  animation: any;
  playWhenVisible?: boolean;
  isParallax?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  scrollTop?: number;
}

interface LottieContainerState {
  isPlaying: boolean;
}

export class LottieContainer extends React.Component<
  LottieContainerProps,
  LottieContainerState
> {
  lottie: React.Ref<Lottie>;
  constructor(props: LottieContainerProps) {
    super(props);
    this.lottie = React.createRef();
    this.state = {
      isPlaying: false,
    };
  }
  toggleAnimation = (isVisible: boolean) => {
    this.setState({ isPlaying: isVisible });
  };
  UNSAFE_componentWillUpdate = (nextProps: any) => {
    nextProps.scrollTop !== this.props.scrollTop && this.setProgress();
  };
  setProgress = () => {
    const lottieRef = this.lottie && this.lottie.current;
    if (lottieRef && lottieRef.anim) {
      const offset = 100;
      const scrollDistance = window.innerHeight - offset * 2;
      const elCenterTop =
        lottieRef.el.getBoundingClientRect().top +
        lottieRef.el.clientHeight / 2;
      if (elCenterTop - offset < scrollDistance) {
        const frameNumber =
          lottieRef.anim.totalFrames -
          Math.round(
            lottieRef.anim.totalFrames / (scrollDistance / elCenterTop)
          );
        lottieRef.anim.goToAndStop(
          frameNumber < 0
            ? 0
            : frameNumber <= lottieRef.anim.totalFrames
            ? frameNumber
            : lottieRef.anim.totalFrames,
          true
        );
      }
      lottieRef.anim.pause();
    } else {
      lottieRef.goToAndStop(0, true);
    }
  };
  render() {
    const {
      autoplay = true,
      loop = true,
      animation,
      isParallax,
      playWhenVisible = false,
    } = this.props;
    const { isPlaying } = this.state;
    if (animation && !playWhenVisible && !isParallax) {
      // regular autoplay lottie
      const defaultOptions = {
        loop: loop,
        autoplay: autoplay,
        animType: 'svg',
        animationData: animation,
      };
      return <Lottie options={defaultOptions} />;
    } else if (animation && playWhenVisible) {
      // play lottie when in view
      const defaultOptions = {
        loop: true,
        autoplay: false,
        animType: 'svg',
        animationData: animation,
      };
      return (
        <VisibilitySensor
          partialVisibility={true}
          minTopValue={
            typeof window !== 'undefined' ? window.innerHeight / 2 : 700
          }
          onChange={isVisible => {
            this.toggleAnimation(isVisible);
          }}
        >
          <LottieOverflowContainer>
            <Lottie options={defaultOptions} isPaused={!isPlaying} />
          </LottieOverflowContainer>
        </VisibilitySensor>
      );
    } else if (animation && isParallax) {
      // controll lottie with scroll position
      const defaultOptions = {
        animType: 'svg',
        loop: false,
        autoplay: false,
        animationData: animation,
      };
      return (
        <LottieOverflowContainer>
          <Lottie
            ref={this.lottie}
            options={defaultOptions}
            isPaused={true}
            isClickToPauseDisabled={true}
          />
        </LottieOverflowContainer>
      );
    } else return null;
  }
}

const LottieOverflowContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
`;
