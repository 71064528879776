import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Text, Heading2 } from '../../helper/Typography';
import { GridBox, GridSection } from '../Grid';
import {
  sectionHeight,
  randomProfileLayout,
  patternConfigurations,
} from './layouts';
import { colors } from '../../helper/Variables';
import ResponsiveBgImage from '../ResponsiveBgImage/ResponsiveBgImage';
import { getLocalizedNodes } from '../../helper/helperServices';

class RandomProfileComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      randomProfile: this.getRandomProfile(),
    };

    this.location = props.location && props.location.pathname;
  }

  getRandomProfile() {
    const teamMembers =
      (this.props.data.allContentfulProfile &&
        getLocalizedNodes(
          this.props.data.allContentfulProfile.nodes,
          this.props.userLanguage
        )) ||
      [];

    let filteredCategoryMembers = new Array(...teamMembers).filter(
      el => el.category === this.props.category
    );

    return filteredCategoryMembers.length > 0
      ? filteredCategoryMembers[
          Math.floor(filteredCategoryMembers.length * Math.random())
        ]
      : teamMembers[Math.floor(teamMembers.length * Math.random())];
  }

  componentDidMount() {
    this.setState({ randomProfile: this.getRandomProfile() });
  }

  UNSAFE_componentWillUpdate(next) {
    if (next.userLanguage !== this.props.userLanguage) {
      this.setState({ randomProfile: this.getRandomProfile() });
    }
  }

  render() {
    const { randomProfile } = this.state;
    const patterConfig =
      (randomProfile &&
        randomProfile.patternConfiguration &&
        patternConfigurations[randomProfile.patternConfiguration]) ||
      1;

    return (
      <GridSection
        gridRows={sectionHeight}
        dark={this.props.dark} //dark atm is default no white pictures
      >
        {randomProfile && (
          <>
            <GridBox
              style={{ alignContent: 'center' }}
              layout={randomProfileLayout.header}
            >
              <Text.pGrey style={{ color: colors.grey }}>
                {this.props.userLanguage === 'de'
                  ? 'Ich bin Neugelb'
                  : 'I am Neugelb'}
              </Text.pGrey>

              <Heading2 darkTheme={this.props.darkTheme} as={'span'}>
                {randomProfile.claim}
              </Heading2>
              <Text.p darkTheme={this.props.darkTheme}>
                {randomProfile.employeeName}, {randomProfile.profession}
              </Text.p>
            </GridBox>

            <GridBox layout={randomProfileLayout.picture}>
              <Anim darkTheme={this.props.darkTheme}>
                <ResponsiveBgImage
                  url={randomProfile.profileImage.file.url}
                  sizes={'768px'}
                />
              </Anim>
            </GridBox>

            {patternConfigurations &&
              randomProfile.patternConfiguration &&
              patterConfig && (
                <GridBox
                  layout={patterConfig.shape1}
                  className={'showAbove-mobile2'}
                >
                  <SvgPattern url={randomProfile.shape1.file.url} />
                </GridBox>
              )}

            {patternConfigurations &&
              randomProfile.patternConfiguration &&
              patterConfig && (
                <GridBox layout={patterConfig.shape2}>
                  <SvgPattern url={randomProfile.shape2.file.url} />
                </GridBox>
              )}
          </>
        )}
      </GridSection>
    );
  }
}

const RandomProfile = ({ userLanguage, darkTheme, category = null }) => (
  <StaticQuery
    query={profilesQuery}
    render={data => (
      <RandomProfileComponent
        data={data}
        category={category}
        userLanguage={userLanguage}
        darkTheme={darkTheme}
      />
    )}
  />
);

export default RandomProfile;

const SvgPattern = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
`;

const Anim = styled.div`
  div {
    width: 100%;
    height: 100%;
    opacity: ${({ darkTheme }) => (darkTheme ? 1 : 0)};
    transition: all 0.8s ease;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const profilesQuery = graphql`
  {
    allContentfulProfile {
      nodes {
        node_locale
        employeeName
        claim
        profileImage {
          file {
            url
          }
        }
        shape1 {
          file {
            url
          }
        }
        shape2 {
          file {
            url
          }
        }
        slug
        profession
        category
        patternConfiguration
      }
    }
  }
`;
