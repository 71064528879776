import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import is from 'is_js';
import { Footer } from '.';
import { IeFallback } from './IeFallback/IeFallback';

interface PageLayoutProps {
  userLanguage: string;
  generalText: Object;
  children: ReactNode;
  darkTheme?: boolean;
}

export const PageLayout = ({
  userLanguage,
  generalText,
  darkTheme = true,
  children,
}: PageLayoutProps) => {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  useEffect(() => {
    !showDisclaimer && setShowDisclaimer(false);
  });

  return (
    <Fragment>
      {is.ie() ? (
        <IeFallback />
      ) : (
        <Fragment>
          {children}
          <Footer
            lang={userLanguage}
            general={generalText}
            darkTheme={darkTheme}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
