import React from 'react';
import styled from 'styled-components';
import { colors, bps } from './Variables';

export const Heading1 = styled.h1`
  display: block;
  font-family: ${({ thin }) =>
    thin ? `'Metric-Regular', sans-serif` : `'Metric-Semibold', sans-serif`};
  /* color: inherit; */
  color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
  transition: color 0.3s ease;

  font-size: 92px;
  line-height: 96px;
  margin: ${({ noMargin }) => (noMargin ? `0` : `0 0 28px 0`)};

  /*NEEDED FOR SAFARI O RENDER THE KERNING CORRECTLY*/
  font-weight: 100;
  @media (max-width: ${bps[2].max}px) {
    font-size: 46px;
    line-height: 48px;
    margin: 0 0 20px 0;
  }
`;

export const Heading2 = styled.h2`
  display: block;
  font-family: 'Metric-Semibold', sans-serif;
  color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
  transition: color 0.3s ease;

  font-size: 44px;
  line-height: 56px;
  margin: 0 0 28px 0;

  /*NEEDED FOR SAFARI O RENDER THE KERNING CORRECTLY*/
  font-weight: 100;
  @media (max-width: ${bps[2].max}px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 20px 0;
  }
`;

export const Heading3 = styled.h3`
  font-family: 'Metric-Semibold', sans-serif;
  color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
  transition: color 0.3s ease;

  font-size: 22px;
  line-height: 32px;
  margin: 0 0 8px 0;

  /*NEEDED FOR SAFARI O RENDER THE KERNING CORRECTLY*/
  font-weight: 100;
  @media (max-width: ${bps[2].max}px) {
    font-size: 20px;
    line-height: 32px;
    margin: 0 0 8px 0;
  }
`;

export const Text = {
  p: styled.p`
    font-family: 'Metric-Regular', sans-serif;
    color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
    transition: color 0.3s ease;

    font-size: 22px;
    line-height: 32px;
    margin: 0 0 28px 0;

    @media (max-width: ${bps[2].max}px) {
      font-size: 20px;
      line-height: 32px;
      margin: 0 0 20px 0;
    }

    a {
      text-decoration: underline;
    }
  `,
  pBold: styled.span`
    font-family: 'Metric-Semibold', sans-serif;
    color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
    transition: color 0.3s ease;

    font-size: 22px;
    line-height: 32px;
    margin: 0 0 28px 0;

    @media (max-width: ${bps[2].max}px) {
      font-size: 20px;
      line-height: 32px;
      margin: 0 0 20px 0;
    }
  `,

  pBoldInfo: styled.p`
    font-family: 'Metric-Semibold', sans-serif;
    color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
    transition: color 0.3s ease;

    font-size: 22px;
    line-height: 32px;
    margin: 0 0 28px 0;

    @media (max-width: ${bps[2].max}px) {
      font-size: 20px;
      line-height: 32px;
      margin: 0 0 20px 0;
    }
  `,
  pGrey: styled.p`
    font-family: 'Metric-Regular', sans-serif;
    color: ${colors.grey};
    transition: color 0.3s ease;
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 28px 0;

    @media (max-width: ${bps[2].max}px) {
      font-size: 20px;
      line-height: 32px;
      margin: 0 0 20px 0;
    }
  `,
  small: styled.small`
    font-family: 'Metric-Regular', sans-serif;
    color: ${colors.grey};
    display: inline-block;

    font-size: 16px;
    line-height: 24px;
    margin: 0 0 28px 0;

    @media (max-width: ${bps[2].max}px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
    }
  `,
  quote: styled.p`
    font-family: 'Metric-Regular', sans-serif;
    color: ${colors.white};
    display: inline-block;

    font-size: 44px;
    line-height: 56px;
    margin: 0;

    @media (max-width: ${bps[2].max}px) {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 10px 0;
    }

    @media (min-width: ${bps[2].max}px) and (max-width: ${bps[4].max}px) {
      font-size: 34px;
      line-height: 38px;
      margin: 0 0 10px 0;
    }
  `,
  author: styled.p`
    font-family: 'Metric-Regular', sans-serif;
    color: ${({ highlightColor }) =>
      highlightColor ? highlightColor : colors.turquoise}!important;
    display: inline-block;

    font-size: 16px;
    line-height: 24px;
    margin: 0;
  `,
};

export const TextSectionTitle = styled(Heading2)`
  @media (min-width: 767px) {
    width: 410px;
  }
`;

export const TextSectionText = styled(Text.p)`
  margin-top: 24px;
  margin-bottom: 12px;
  @media (min-width: 1024px) {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;

export const List = props => {
  return <UnorderedList>{props.children}</UnorderedList>;
};

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 20px;
  margin-left: -20px;
`;
export const ListItem = styled.li`
  margin-bottom: 32px;
  line-height: 32px;
  padding: 0;
  position: relative;
  color: ${({ darkTheme }) => (darkTheme ? colors.white : colors.black)};
`;
