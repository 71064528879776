// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  intro: [6, 6, 7, 8, 6, 6, 6, 7, 8],
  video: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  teaser: [7, 7, 6, 6, 6, 5, 5, 5, 5],
  banner: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  team: [4, 4, 3, 5, 6, 4, 4, 4, 4],
  jobs: [6, 6, 6, 7, 7, 6, 6, 6, 6],
};

export const intro = {
  title: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 5, 4, 3, 3, 3, 3, 4],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 3, 4, 4, 4, 4, 4, 4],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  eye: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 3, 2, 4, 2, 2, 2, 3],
    left: [3, 3, 4, 6, 8, 7, 9, 10, 11],
  },
  eyeStart: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 3, 2, 4, 2, 2, 3, 3],
    left: [3, 3, 4, 6, 8, 7, 9, 10, 11],
  },
  eyeEnd: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 3, 1, 6, 6, 6, 7, 8],
    left: [3, 3, 4, 6, 8, 7, 9, 10, 11],
  },
};

export const patternsLayout = {
  p1: {
    top: [2, 2, 4, 3, 3, 3, 3, 3, 4],
    left: [3, 3, 4, 6, 8, 8, 10, 11, 12],
  },
  p2: {
    top: [5, 5, 5, 7, 5, 5, 5, 5, 6],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 3],
  },
  p3: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [2, 2, 2, 3, 5, 7, 8, 11, 12],
  },
  p4: {
    top: [6, 6, 6, 6, 6, 5, 5, 5, 5],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
  },
  p5: {
    top: [6, 6, 5, 5, 5, 4, 4, 5, 5],
    left: [2, 2, 4, 5, 6, 6, 8, 9, 10],
  },
  p6: {
    top: [5, 5, 6, 6, 6, 5, 5, 6, 6],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 3],
  },
  p7: {
    top: [6, 6, 6, 6, 6, 5, 5, 6, 6],
    left: [1, 1, 2, 3, 4, 4, 6, 7, 8],
  },
  p8: {
    top: [6, 6, 6, 6, 6, 5, 5, 6, 6],
    left: [2, 2, 3, 4, 5, 5, 7, 8, 9],
  },
};

export const teaser = {
  top: [3, 3, 2, 2, 2, 2, 2, 2, 2],
  left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
  boxWidth: [3, 3, 4, 4, 6, 6, 6, 6, 6],
  boxHeight: [4, 4, 4, 4, 3, 3, 3, 3, 3],
};

export const boltLayout = {
  top: [7, 7, 6, 6, 6, 5, 5, 5, 5],
  left: [1, 1, 1, 3, 4, 4, 5, 6, 7],
};

export const heartLayout = {
  top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  left: [3, 3, 1, 2, 2, 2, 3, 4, 5],
};

export const team = {
  header: {
    top: [2, 2, 2, 1, 3, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [2, 2, 5, 5, 2, 2, 2, 2, 2],
  },
  slider: {
    top: [4, 4, 7, 6, 5, 5, 5, 5, 5],
    left: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    boxWidth: [3, 3, 4, 6, 8, 8, 10, 12, 14],
    boxHeight: [4, 4, 4, 4, 4, 4, 4, 4, 4],
  },
};

export const gallery = {
  //bps:        [1, 2, 3, 4, 5, 6, 7, 8, 9]
  laptopImg: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 2, 2, 2],
    boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  cristina: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [3, 3, 3, 4, 4, 4, 4, 4, 4],
    boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  prints: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [5, 5, 5, 6, 6, 6, 6, 6, 6],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  standup: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [6, 6, 6, 7, 7, 7, 7, 7, 7],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  unico: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [9, 9, 9, 10, 10, 10, 10, 10, 10],
    boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    boxHeight: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
  designers: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [10, 10, 10, 11, 11, 12, 12, 12, 12],
    boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  //bps:        [1, 2, 3, 4, 5, 6, 7, 8, 9]
  nationalities: {
    top: [1, 1, 1, 1, 1, 2, 2, 2, 2],
    left: [4, 4, 4, 5, 5, 4, 4, 4, 4],
    boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  dogs: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [9, 9, 9, 10, 10, 10, 10, 10, 10],
  },
  people: {
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [8, 8, 8, 9, 9, 9, 9, 9, 9],
  },
  //bps:        [1, 2, 3, 4, 5, 6, 7, 8, 9]
  p1: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 3, 4, 4, 4, 4, 4, 4],
  },
  p2: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [5, 5, 5, 6, 6, 6, 6, 6, 6],
  },
  p3: {
    top: [4, 4, 4, 4, 4, 5, 5, 5, 5],
    left: [5, 5, 5, 6, 6, 4, 4, 4, 4],
  },
  p4: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [8, 8, 8, 9, 9, 9, 9, 9, 9],
  },
  p5: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [8, 8, 8, 9, 9, 9, 9, 9, 9],
  },
  p6: {
    top: [1, 1, 1, 2, 2, 2, 2, 2, 2],
    left: [9, 9, 9, 11, 11, 11, 11, 11, 11],
  },
};

export const jobs = {
  teaser: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [5, 5, 3, 2, 2, 2, 2, 2, 2],
  },
};
