export const sectionHeight = {
    //bps:                  [1, 2, 3, 4, 5, 6, 7, 8, 9]
    contentSection:         [6, 6, 8, 4, 4, 4, 4, 4, 4],
    contentSectionExtended: [7, 7, 8, 4, 4, 4, 4, 4, 4],
    creditSection:          [0, 0, 0, 2, 2, 2, 2, 2, 2],
};

export const contentSection = {
    text: {
        top: [1, 1, 2, 2, 2, 2, 2, 2, 2],
        left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
        boxWidth: [3, 3, 4, 2, 2, 3, 3, 3, 3],
    },
    animation: {
        top:        [2, 2, 4, 1, 1, 1, 1, 1, 1],
        left:       [1, 1, 1, 4, 5, 5, 6, 7, 8],
        boxWidth:   [3, 3, 4, 3, 3, 4, 4, 4, 4],
        boxHeight:  [3, 3, 4, 3, 3, 4, 4, 4, 4],
    },
    animationExtended: {
        top:        [3, 3, 4, 1, 1, 1, 1, 1, 1],
        left:       [1, 1, 1, 4, 5, 5, 6, 7, 8],
        boxWidth:   [3, 3, 4, 3, 3, 4, 4, 4, 4],
        boxHeight:  [3, 3, 4, 3, 3, 4, 4, 4, 4],
    }
};

export const creditSection = {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [4, 4, 4, 5, 5, 4, 4, 4, 4],
};