import React, { Component } from 'react';
import Modal from 'react-modal';
import './VideoOverlay.css';
import { SimpleImage } from '../../helper/StyledHelper';
import Video from './Video';

interface VideoOverlayProps {
  playButton: React.ReactNode | HTMLElement;
  videoId: string;
}
interface VideoOverlaySate {
  modalIsOpen: boolean;
}
const defaultStyles = {
  overlay: {
    backgoundColor: 'transparent',
    zIndex: '99999',
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    overflow: 'hidden',
  },
};
Modal.defaultStyles = defaultStyles;
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

export class VideoOverlay extends Component<
  VideoOverlayProps,
  VideoOverlaySate
> {
  constructor(props: VideoOverlayProps) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.triggerModal = this.triggerModal.bind(this);
  }

  triggerModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen }, (): void => {
      // toggle class to prevent background scroling also on html element
      if (this.state.modalIsOpen)
        document
          .getElementsByTagName('html')[0]
          .classList.add('ReactModal__Body--open');
      else
        document
          .getElementsByTagName('html')[0]
          .classList.remove('ReactModal__Body--open');
    });
  }

  customStyles = {
    content: {
      backgroundColor: 'rgba(0, 0, 0, 1)',
      border: '0 solid',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: '0',
      position: 'relative',
      height: '100%',
      boxSizing: 'border-box',
      padding: 0,
      overflow: 'hidden',
    },
  };
  render() {
    const { children, playButton, videoId } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div className="modal">
        <div className="modal__openTrigger" onClick={this.triggerModal}>
          {children}
          <div className="modal__triggerOverlay">{playButton}</div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.triggerModal}
          style={this.customStyles}
        >
          <div className="modal__videoContainer">
            <Video videoId={videoId} closeModal={this.triggerModal} />
          </div>
          <div onClick={this.triggerModal} className="modal__closeTrigger">
            <SimpleImage src="/assets/icons/close-icon-white.svg"></SimpleImage>
          </div>
        </Modal>
      </div>
    );
  }
}

export default VideoOverlay;
