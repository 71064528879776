export const bpMax = {
  mobile: 768,
  tablet1: 1024,
  tablet2: 1280,
  desktop: 2600,
};

export const generateResponsiveImages = imgURL => ({
  mobile: `${imgURL}?w=${bpMax.mobile}&fit=thumb`,
  tablet1: `${imgURL}?w=${bpMax.tablet1}&fit=thumb`,
  tablet2: `${imgURL}?w=${bpMax.tablet2}&fit=thumb`,
  desktop: imgURL,
});

export const srcSetGenerator = imgURL => {
  const resImages = generateResponsiveImages(imgURL);
  return `${Object.keys(bpMax).map(bp => `${resImages[bp]} ${bpMax[bp]}w`)}`;
};
