import React, { Component } from 'react';
import './VideoOverlay.css';
import Player from '@vimeo/player';
import './VideoOverlay.css';

interface VideoProps {
  videoId: string;
  closeModal: Function;
}

interface VideoState {
  videoWidth: number;
  videoHeight: number;
}

const ratio = 16 / 9;

export class Video extends Component<VideoProps, VideoState> {
  playerRef: React.Ref<any>;
  player: Player;
  constructor(props: any) {
    super(props);
    this.playerRef = React.createRef();
    this.state = {
      videoWidth: this.setWidth(),
      videoHeight: this.setHeight(),
    };
  }
  setWidth = () => {
    if (window.innerWidth / ratio <= window.innerHeight) {
      return window.innerWidth;
    } else {
      return window.innerHeight * ratio;
    }
  };

  setHeight = () => {
    if (window.innerWidth / ratio <= window.innerHeight) {
      return window.innerWidth / ratio;
    } else {
      return window.innerHeight;
    }
  };

  componentDidMount = () => {
    const isIos =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    try {
      this.player = new Player(this.playerRef && this.playerRef.current);
      this.player.on('loaded', () => {
        if (!isIos && typeof window !== 'undefined') {
          this.player
            .play()
            .then()
            .catch((error: Error) => {
              this.props.closeModal();
              console.error(`Vimeo-Error: ${error}`);
            });
        }
      });
    } catch (error) {
      this.props.closeModal();
      console.error(`Vimeo-Error: ${error}`);
    }

    window.addEventListener('resize', this.setVideDimensions.bind(this));
  };

  setVideDimensions() {
    this.setState({
      videoWidth: this.setWidth(),
      videoHeight: this.setHeight(),
    });
  }

  render() {
    return (
      <iframe
        ref={this.playerRef}
        src={`https://player.vimeo.com/video/${this.props.videoId}`}
        width={this.state.videoWidth}
        height={this.state.videoHeight}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    );
  }
}

export default Video;
