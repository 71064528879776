import React from 'react';
import styled from 'styled-components';
import { GridBox } from '../Grid';
import { gallery } from '../../templates/home/layouts';
import { Image } from '../Image/Image';
import { Text } from '../../helper/Typography';
import { SimpleImage } from '../../helper/StyledHelper';
import { graphql, StaticQuery } from 'gatsby';
import { getLocalizedNode } from '../../helper/helperServices';

const GalleryComponent = props => {
  const { layout, darkTheme, userLanguage } = props;
  const companyFacts =
    (props.data.allContentfulCompanyFacts &&
      getLocalizedNode(
        props.data.allContentfulCompanyFacts.nodes,
        userLanguage
      )) ||
    {};
  return (
    <>
      <OverflowContainer>
        <GalleryContainer>
          <GalleryGridContainer>
            <GridBox layout={layout.laptopImg}>
              <Image imageFolderUrl={'/assets/pages/home/gallery/cristina/'} />
            </GridBox>
            <GridBox layout={layout.cristina}>
              <Image imageFolderUrl={'/assets/pages/home/gallery/laptopImg/'} />
            </GridBox>
            <GridBox layout={layout.prints}>
              <Image imageFolderUrl={'/assets/pages/home/gallery/federica/'} />
            </GridBox>
            <GridBox layout={layout.standup}>
              <Image imageFolderUrl={'/assets/pages/home/gallery/standup/'} />
            </GridBox>
            <GridBox layout={layout.unico}>
              <Image
                imageFolderUrl={'/assets/pages/home/gallery/developers/'}
              />
            </GridBox>
            <GridBox layout={layout.designers}>
              <Image imageFolderUrl={'/assets/pages/home/gallery/designers/'} />
            </GridBox>

            <GridBox layout={layout.nationalities}>
              <Text.p
                darkTheme={darkTheme}
                style={{ placeSelf: 'center', marginBottom: 0 }}
              >
                {companyFacts.nationalities}
              </Text.p>
            </GridBox>
            <GridBox layout={gallery.dogs}>
              <Text.p
                darkTheme={darkTheme}
                style={{ placeSelf: 'center', marginBottom: 0 }}
              >
                {companyFacts.dogs}
              </Text.p>
            </GridBox>
            <GridBox layout={gallery.people}>
              <Text.p
                darkTheme={darkTheme}
                style={{ placeSelf: 'center', marginBottom: 0 }}
              >
                {companyFacts.people}
              </Text.p>
            </GridBox>

            <GridBox layout={layout.p1}>
              <SimpleImage
                src={'/assets/pages/home/gallery/_patterns/p1.svg'}
              />
            </GridBox>
            <GridBox layout={layout.p2}>
              <SimpleImage
                src={'/assets/pages/home/gallery/_patterns/p2.svg'}
              />
            </GridBox>
            <GridBox layout={layout.p3}>
              <SimpleImage
                src={'/assets/pages/home/gallery/_patterns/p3.svg'}
              />
            </GridBox>
            <GridBox layout={layout.p4}>
              <SimpleImage
                src={'/assets/pages/home/gallery/_patterns/p4.svg'}
              />
            </GridBox>
            <GridBox layout={layout.p5}>
              <SimpleImage
                src={'/assets/pages/home/gallery/_patterns/p5.svg'}
              />
            </GridBox>
            <GridBox layout={layout.p6}>
              <SimpleImage
                src={'/assets/pages/home/gallery/_patterns/p6.svg'}
              />
            </GridBox>
          </GalleryGridContainer>
        </GalleryContainer>
      </OverflowContainer>
    </>
  );
};

const Gallery = ({ layout, userLanguage, darkTheme }) => (
  <StaticQuery
    query={galleryQuery}
    render={data => (
      <GalleryComponent
        data={data}
        layout={layout}
        darkTheme={darkTheme}
        userLanguage={userLanguage}
      />
    )}
  />
);

export default Gallery;

const OverflowContainer = styled.div`
  display: block;

  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  }

  height: calc(100px * 5);
  max-width: 100vw;
  @media (min-width: 321px) and (max-width: 767px) {
    height: calc(112px * 5);
  }
  @media (min-width: 768px) and (max-width: 1279px) {
    height: calc(120px * 5);
  }
  @media (min-width: 1280px) {
    height: calc(160px * 5);
  }
`;
const GalleryContainer = styled.div`
  @media (min-width: 768px) and (max-width: 1279px) {
    min-width: 1680px;
    max-width: 1680px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: 1280px) {
    min-width: 2240px;
    max-width: 2240px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const GalleryGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 100px);
  grid-template-rows: repeat(5, 100px);
  @media (min-width: 321px) and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(12, 112px);
    grid-template-rows: repeat(5, 112px);
  }
  @media (min-width: 768px) and (max-width: 1279px) {
    display: grid;
    grid-template-columns: repeat(14, 120px);
    grid-template-rows: repeat(5, 120px);
  }
  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(14, 160px);
    grid-template-rows: repeat(5, 160px);
  }
`;

export const galleryQuery = graphql`
  {
    allContentfulCompanyFacts {
      nodes {
        node_locale
        nationalities
        dogs
        people
      }
    }
  }
`;
