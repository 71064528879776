import smoothScroll from 'smoothscroll-polyfill';
import { throttle } from './helperServices';

const anchors = {};
let referencedHistory: any = undefined;

if (typeof window !== 'undefined') {
  referencedHistory = window.history;
  // Add the smoothScroll polyfill
  smoothScroll.polyfill();
  // add a global scroll event listener that keeps track of the current 'active' Anchor
  const timeout = undefined;
  const throttleScrollEvent = () => throttle(timeout, anchorScrollListener, 50);
  window.addEventListener('scroll', throttleScrollEvent, false);
}

function anchorScrollListener() {
  let best: any = undefined;
  let nearest: number = -99999999;

  if (typeof document !== 'undefined' && document && document.body) {
    const atBottom =
      document.body.getBoundingClientRect().bottom < window.innerHeight + 5;

    Object.keys(anchors).forEach(key => {
      // Offset 75 so it switches 75 pixels earlier
      const top =
        anchors[key].getBoundingClientRect().top - window.innerHeight * 0.3;

      // if we are at bottom of page or this element is above view
      //  *and* it is below the last best -> take as best
      if (best === undefined || ((top <= 0 || atBottom) && top > nearest)) {
        nearest = top;
        best = key;
      }
    });

    if (best && '#' + best !== window.location.hash) {
      /* console.log(referencedHistory); */
      // we got a new 'best' anchor -> trigger event and update window.location hash
      if (referencedHistory.pushState) {
        referencedHistory.replaceState({}, '', '#' + best);
      } else if ('function' === typeof referencedHistory.push) {
        referencedHistory.replace(
          `${referencedHistory.location.pathname}#${best}`,
          {}
        );
      } else {
        // Fallback for the poors browsers which do not have pushState
        window.location.hash = best;
      }
      triggerAnchorChangeEvent(best);
    }
  }
}

function setHistoryObject(routerHistory: {}) {
  referencedHistory = routerHistory;
}

function triggerAnchorChangeEvent(active: boolean) {
  if (typeof CustomEvent === 'function') {
    // use common new api if available
    document.dispatchEvent(
      new CustomEvent('internal.active-anchor-change', {
        bubbles: true,
        detail: active,
      })
    );
  } else {
    // use fallback for IE
    let event = document.createEvent('CustomEvent');
    event.initCustomEvent('internal.active-anchor-change', true, true, active);
    document.dispatchEvent(event);
  }
}

function scrollSmoothlyTo(
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  id: string,
  offsetSpace: number = 1
) {
  event.preventDefault();
  const anchor = anchors[id];
  if (anchor) {
    window.scroll({
      top: anchor.offsetTop + offsetSpace - 80,
      behavior: 'smooth',
    });
  } else {
    console.error(
      `The id (${id}) is unknown. Have you added an Anchor component with that id to your app?`
    );
  }
}

export const scrollHelper = {
  anchors: anchors,
  setHistoryObject: setHistoryObject,
  scrollSmoothlyTo: scrollSmoothlyTo,
};
